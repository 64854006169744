import api from "../../../../api";
import { toast } from "react-toastify";
import { useState, useRef, ChangeEvent, useEffect, useCallback } from "react";
import { getSpaceUntilMaxLength } from "@testing-library/user-event/dist/utils";

const useUploadUserList = (
  employeeRef: any,
  investeeRef: any,
  uploadPopup: string,
  setUploadPopup: any,
  activeSubTab: string | null
) => {
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [fileUploadLoading, setFileUploadLoading] = useState<boolean>(false);
  const [sampleFile, setSampleFile] = useState("");
  const [sampleFileLoading, setSampleFileLoading] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileUploadClick = () => {
    fileInputRef?.current?.click();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      setSelectedFile(file);
    } else {
      console.log("even doesnt failed");
    }
  };

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
  };

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
  };
  const [csvFiles, setCsvFiles] = useState<File[]>([]);

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    const droppedFiles = Array.from(e.dataTransfer.files);
    const csvFiles = droppedFiles.filter((file) => file.type === "text/csv");
    setCsvFiles(csvFiles);
  };

  const submitForm = async (
    e: React.FormEvent<HTMLFormElement>,
    file: any,
  ) => {
    e.preventDefault();

    if (!selectedFile) {
      toast.error("Please upload file");
      return;
    }

    let form_data = new FormData();
    form_data.append("csv", file);

    const apiEndPoint =
      uploadPopup === "employee"
        ? api.config.uploademployeeList()
        : api.config.uploadInvesteeList();

    setFileUploadLoading(true);

    try {
      const response = await fetch(apiEndPoint, api.http.postForm(form_data));

      api.afterFetchHandlers.checkStatus(response);

      setSelectedFile(null);
      setUploadPopup("");
      toast.success("Data added successfully!");

      if(uploadPopup === 'employee') {
        employeeRef.current?.getEmployees();
        return;
      }
      investeeRef.current?.getInvestees();

    } catch (error: any) {
      const result = await error.json();

      if (result.validationErrors) {
        toast.error("Please validate the data in the file");
        return;
      }
      toast.error(result?.error || "Something went wrong");
    } finally {
      setFileUploadLoading(false);
    }
  };

  const getSampleFile = useCallback(async () => {
    try {
      setSampleFileLoading(true);

      const apiUrl = (uploadPopup === 'employee') ? `${api.config.getUserUploadSampleFile()}?type=employee` : `${api.config.getUserUploadSampleFile()}?type=investee`;

      const response = await fetch(apiUrl, api.http.get());

      api.afterFetchHandlers.checkStatus(response);

      const result = await api.afterFetchHandlers.parseContent(
        response,
        api.http.get()
      );
      setSampleFile(result.result || []);
    } catch (error: any) {
      const result = await error.json();
      toast.error(result?.error || "Something went wrong");
    } finally {
      setSampleFileLoading(false);
    }
  }, [uploadPopup]);

  useEffect(() => {
    getSampleFile();
  }, [getSampleFile]);

  useEffect(() => {
    setSelectedFile(null);
    setSampleFile("");
  }, [activeSubTab]);

  return {
    fileInputRef,
    fileUploadLoading,
    submitForm,
    handleFileChange,
    selectedFile,
    handleDrop,
    handleDragOver,
    handleFileUploadClick,
    sampleFile,
    sampleFileLoading
  };
};

export default useUploadUserList;
