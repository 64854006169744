import userIcon from "../../../../../assets/svg/userIcon.svg";
import './UserEmptyState.scss';

type Texts = {
  text: string;
  subText: string;
}

const UserEmptyState = ({text, subText} : Texts) => {
  return <div className="empty-state-container">
    <img src={userIcon} />
    <p>{text}</p>
    <p className="sub-text">{subText}</p>
  </div>;
};

export default UserEmptyState;
