import React, { useEffect, useState } from "react";
import "./ClimateJourney.scss";
import { Link, useLocation } from "react-router-dom";
import Plans from "./Plans";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { getModuleDetails } from "../../../redux/slice/cartSlice/cartApiSlice";
import ButtonWithSpinner from "../../components/Utils/ButtonWithSpinner/ButtonWithSpinner";
import { toast } from "react-toastify";

const ClimateJourney = () => {
  const location = useLocation();
  const sector = location.state.sector;
  const dispatch = useDispatch<AppDispatch>();
  const [cart, setCart] = useState([]);
  const [loading, setLoading] = useState(false);

  const userLogin = useSelector((state: any) => state.userLogin || {});

  const userRole = userLogin.response?.result?.data?.payload?.user_role;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const result = await dispatch(getModuleDetails(null));
        setCart(
          result.payload?.data?.filter((cart: any) => cart.sector == sector)[0]
        );
      } catch (error: any) {
        toast.error(error?.message || "Something went wrong");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="container">
      <div className="content">
        <h3> Your Climate Journey</h3>

        {userRole === "admin" ? (
          <Link
            className="manage-users"
            to={{
              pathname: "/manageusers",
            }}
            style={loading ? { pointerEvents: "none" } : {}}
            state={{ sector: sector, cart: cart }}
          >
            <ButtonWithSpinner isLoading={loading}>
              Manage Users
            </ButtonWithSpinner>
          </Link>
        ) : null}
      </div>

      <div className="sub-container">
        <Plans sector={sector} cart={cart} />
      </div>
    </div>
  );
};

export default ClimateJourney;
