import "./AccountSetting.scss";
import EditIcon from "../../../../assets/svg/EditIcon";
import useAccountSetting from "./useAccountSetting";
import TransparentLoader from "../../Utils/Loader/TransparentLoader";

const AccountSetting = () => {
  const {
    formData,
    names,
    companyName,
    password,
    handleChange,
    handleEdit,
    handleSave,
    error,
    validation,
    loading,
    updateDetailsLoading
  } = useAccountSetting();

  if(loading) return <TransparentLoader />;

  return (
    <div className="accountsetting">
      <h1> Your Profile</h1>
      <div className="account-setting-form">
        <div className="form-section">
          <div className="content">
            <div className={names ? "main-content expand" : "main-content"}>
              <label htmlFor="firstName">
                <h3>First Name</h3>
                <div className="inputAccountDetails">
                  <input
                    name="firstName"
                    type="text"
                    value={formData.firstName}
                    onChange={handleChange}
                    disabled={!names}
                    placeholder="Enter First Name"
                  />
                  {error?.firstName ? <p className="err-text">First Name is required.</p> : null}
                </div>
              </label>

              <label htmlFor="lastName">
                <h3 style={{ marginTop: "0.6rem" }}>Last Name</h3>
                <div className="inputAccountDetails">
                  <input
                    name="lastName"
                    type="text"
                    value={formData.lastName}
                    onChange={handleChange}
                    disabled={!names}
                    placeholder="Enter Last Name"
                  />
                  {error?.lastName ? <p className="err-text">Last Name is required.</p> : null}
                </div>
              </label>
            </div>

            {!names ? (
              <button
                className="edit-btn names-edit-btn"
                onClick={() => handleEdit("names")}
              >
                <EditIcon color="#fff" width={16} height={16} /> Edit
              </button>
            ) : null}
          </div>

          {names ? (
            <div className="btn-container">
              <button
                className="cancel-btn"
                onClick={() => handleEdit("names")}
              >
                Cancel
              </button>

              <button onClick={() => handleSave({src:'names'})} disabled={updateDetailsLoading}>{updateDetailsLoading ? 'Saving...' : 'Save'}</button>
            </div>
          ) : null}
        </div>

        <div className="form-section">
          <div className="content">
            <div
              className={companyName ? "main-content expand" : "main-content"}
            >
              <label htmlFor="companyName">
                <h3>Company Name</h3>
                <div className="inputAccountDetails">
                  <input
                    name="companyName"
                    type="text"
                    value={formData.companyName}
                    onChange={handleChange}
                    disabled={!companyName}
                    placeholder="Enter Company Name"
                  />
                  {error?.companyName ? <p className="err-text">Company Name is required.</p> : null}
                </div>
              </label>
            </div>

            {!companyName ? (
              <button
                className="edit-btn"
                onClick={() => handleEdit("companyName")}
              >
                <EditIcon color="#fff" width={16} height={16} /> Edit
              </button>
            ) : null}
          </div>

          {companyName ? (
            <div className="btn-container">
              <button
                className="cancel-btn"
                onClick={() => handleEdit("companyName")}
              >
                Cancel
              </button>
              <button onClick={() => handleSave({src:'companyName'})} disabled={updateDetailsLoading}>{updateDetailsLoading ? 'Saving...' : 'Save'}</button>
            </div>
          ) : null}
        </div>

        <div className="form-section">
          {password ? (
            <>
              <h3 style={{ marginBottom: "0.4rem" }}>Reset Password</h3>

              <div className="pass-field">
                <div className="pass-title">Old Password</div>
                <input
                  name="password"
                  type="password"
                  value={formData.password}
                  onChange={handleChange}
                  placeholder="Old Password"
                />
              </div>

              <div className="pass-field">
                <div className="pass-title">New Password</div>

                <input
                  name="newPassword"
                  type="password"
                  value={formData.newPassword}
                  onChange={handleChange}
                  placeholder="New Password"
                />
              </div>

              <div className="pass-field">
                <div className="pass-title">Confirm Password</div>
                <input
                  name="confirmPassword"
                  type="password"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  placeholder="Retype Password"
                />
              </div>

              <ul>
                  {validation.length ? (
                    <li style={{ color: "green" }}>Use 8 or more character</li>
                  ) : (
                    <li style={{ color: "red" }}>Use 8 or more character</li>
                  )}
                  {validation.upperCase ? (
                    <li style={{ color: "green" }}>One Uppercase character</li>
                  ) : (
                    <li style={{ color: "red" }}>One Uppercase character</li>
                  )}
                  {validation.specialCase ? (
                    <li style={{ color: "green" }}>One Special character</li>
                  ) : (
                    <li style={{ color: "red" }}>One Special character</li>
                  )}
                  {validation.lowerCase ? (
                    <li style={{ color: "green" }}>One Lowercase character</li>
                  ) : (
                    <li style={{ color: "red" }}>One Lowercase character</li>
                  )}
                  {validation.number ? (
                    <li style={{ color: "green" }}>One number</li>
                  ) : (
                    <li style={{ color: "red" }}>One number</li>
                  )}
                </ul>

            </>
          ) : (
            <button
              className="edit-btn password-edit-btn"
              onClick={() => handleEdit("password")}
            >
              <EditIcon color="#fff" width={16} height={16} /> Edit Password
            </button>
          )}

          {password ? (
            <div className="btn-container">
              <button
                className="cancel-btn"
                onClick={() => handleEdit("password")}
              >
                Cancel
              </button>
              <button onClick={() => handleSave({src:'password'})} disabled={updateDetailsLoading}>{updateDetailsLoading ? 'Saving...' : 'Save'}</button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default AccountSetting;
