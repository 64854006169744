import "./NotFound.scss";

const NotFound = () => {
  return (
    <div className="not-found-container">
      <div style={{ fontSize:'4rem' }}>404</div>
      <div>Page not found</div>
    </div>
  );
};

export default NotFound;
