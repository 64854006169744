import React from "react";
import "./UserProfile.scss";
import { useNavigate } from "react-router-dom";
import SignOut from "../../../assets/svg/userProfileAsset/SignOut.svg";
import profileSections from "./profileSections";

interface DropdownContentProps {
  userRole: string;
  handleProfileLogout: () => void;
}

type ProfileSection = {
  title: string;
  img: any;
  pathUrl: string;
};

const UserProfile: React.FC<DropdownContentProps> = ({
  userRole,
  handleProfileLogout,
}) => {
  const navigate = useNavigate();

  return (
    <div className="UserProfileDropdown">
      <ul>
        {profileSections.map(({ title, pathUrl, img }: ProfileSection) => {
          if (userRole === "user" && pathUrl === "/orderhistory") {
            return null;
          }
          return (
            <li key={title} onClick={() => navigate(pathUrl)}>
              <img src={img} alt="" />
              {title}
            </li>
          );
        })}

        <li onClick={handleProfileLogout}>
          <img src={SignOut} alt="" />
          Sign out
        </li>
      </ul>
    </div>
  );
};

export default UserProfile;
