import { useEffect, useState, useImperativeHandle, useCallback } from "react";
import api from "../../../../api";
import { toast } from "react-toastify";

const useGetEmployees = (employeeRef: any, modules: any) => {
  const [employeeList, setEmployeeList] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [search, setSearch] = useState("");

  const [formData, setFormData] = useState({});
  const [showFilterPopover, setShowFilterPopover] = useState(false);
  const [subSection, setSubSection] = useState("");
  const [queryString, setQueryString] = useState({
    search: "",
    filter: "",
  });

  const filtersInitialState = modules.reduce(
    (acc: any, curr: any) => ({
      ...acc,
      [curr.value]: {
        isChecked: false,
        read: false,
        write: false,
      },
    }),
    {}
  );

  const modulesInitialState = modules.reduce(
    (acc: any, curr: any) => ({
      ...acc,
      [curr.value]: false,
    }),
    {}
  );

  const [filters, setFilters] = useState(filtersInitialState);
  const [openModule, setOpenModule] = useState(modulesInitialState);

  const getEmployees = useCallback(async () => {
    const filteredQueryString = Object.fromEntries(
      Object.entries(queryString).filter(([key, value]) => value !== "")
    );

    const queryParams = new URLSearchParams(filteredQueryString);

    const queryStr = queryParams.toString()?.replaceAll('+', ',');  

    const url = queryStr
      ? `${api.config.getEmployees()}?${queryStr}`
      : api.config.getEmployees();  

    try {
      setLoading(true);
      const response = await fetch(url, api.http.get());
      const result = await api.afterFetchHandlers.parseContent(
        response,
        api.http.get()
      );
      setEmployeeList(result.result || []);
    } catch (error: any) {
      toast.error(error?.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  }, [setEmployeeList, queryString]);

  const handleSearchApply = () => {
    setQueryString((prev) => ({
      ...prev,
      search: search,
    }));

    setSearch("");
  };

  const handleResetFilters = () => {
    setOpenModule(modulesInitialState);
    setFilters(filtersInitialState);
    setShowFilterPopover(false);
    setQueryString((prev: any) => ({
      ...prev,
      filter: "",
    }))
  };

  const handleApplyFilters = () => {
    setShowFilterPopover(false);

    const checkboxesSelected: string[] = [];

    Object.entries(filters).forEach(
      ([key, value]: [key: string, value: any]) => {
        if (value.isChecked && !value.read && !value.write) {
          checkboxesSelected.push(key);
          return;
        }
        if (value.read) {
          checkboxesSelected.push(`${key}-read`);
        }
        if (value.write) {
          checkboxesSelected.push(`${key}-write`);
        }
      }
    );

    setQueryString((prev) => ({
      ...prev,
      filter: checkboxesSelected.join(","),
    }));
  };

  const togglePopover = () => {
    setShowFilterPopover((prev) => !prev);
  };

  useEffect(() => {
    getEmployees();
  }, [getEmployees]);

  useImperativeHandle(
    employeeRef,
    () => ({
      getEmployees,
    }),
    [getEmployees]
  );

  return {
    employeeList,
    getEmployees,
    loading,
    formData,
    setFormData,
    handleSearchApply,
    handleApplyFilters,
    handleResetFilters,
    filters,
    setFilters,
    showFilterPopover,
    setShowFilterPopover,
    togglePopover,
    search,
    setSearch,
    subSection,
    setSubSection,
    openModule,
    setOpenModule,
    queryString,
    setQueryString,
  };
};

export default useGetEmployees;
