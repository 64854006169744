import { useEffect, useState } from "react";
import "./OrderHistory.scss";
import { getModuleDetails } from "../../../../redux/slice/cartSlice/cartApiSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../redux/store";
import { useNavigate } from "react-router-dom";
import TransparentLoader from "../../Utils/Loader/TransparentLoader";
import { toast } from "react-toastify";

const UserProfile = () => {
  const [orders, setOrders] = useState([]);
  const [orderHistory, setOrderHistory] = useState([]);
  const [selectedSector, setSelectedSector] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const result = await dispatch(getModuleDetails(null));
        setOrders(result.payload?.data);
        setOrderHistory(result.payload?.data?.[0]?.items);
        setSelectedSector(result.payload?.data?.[0]?.sector);
      } catch (error: any) {
        toast.error(error?.message || 'Something went wrong');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSectorClick = (sector: string) => {
    setSelectedSector(sector);
    const currentOrder: any = orders.find((item: any) => item.sector == sector);
    console.log(currentOrder?.items);
    setOrderHistory(currentOrder?.items);
  };

  if(loading) return <TransparentLoader />;

  return (
    <div className="orderHistory">
      <div className="heading">Your Purchases</div>
      <div className="subheadingrow">
        <div className="subheading">
          <div className="sector">
            {orders?.map((item: any, index) => (
              <div
                className={`sectorName ${
                  selectedSector === item.sector ? "active-sector" : ""
                }`}
                onClick={() => handleSectorClick(item.sector)}
                key={item.sector}
              >
                Net-Zero {item.sector}
              </div>
            ))}
          </div>
        </div>
        <div className="contact">
          <button onClick={() => navigate('/contact-support')}>Contact Support</button>
        </div>
      </div>

      <div className="orders">
        {orderHistory.map((item: any, index) => (
          <div className="order">
            <div className="top-details-container">
              <div className="title-text">Billing Date: 13th February</div>
              <div style={{ color: "#9C9C9C" }}>Order Number: 12345678</div>
            </div>

            <div className="modules-container">
              <div className="module-details">
                <div style={{ color: "#253344" }}>GHG Accounting</div>
                <div className="plan-details">Basic Plan</div>
              </div>
              <div className="module-details">
                <div style={{ color: "#253344" }}>GHG Accounting</div>
                <div className="plan-details">Basic Plan</div>
              </div>
              <div className="module-details">
                <div style={{ color: "#253344" }}>GHG Accounting</div>
                <div className="plan-details">Basic Plan</div>
              </div>

              <div className="module-details">
                <div style={{ color: "#253344" }}>GHG Accounting</div>
                <div className="plan-details">Basic Plan</div>
              </div>
              <div className="module-details">
                <div style={{ color: "#253344" }}>GHG Accounting</div>
                <div className="plan-details">Basic Plan</div>
              </div>

              <div className="module-details">
                <div style={{ color: "#253344" }}>GHG Accounting</div>
                <div className="plan-details">Basic Plan</div>
              </div>

              <div className="module-details">
                <div style={{ color: "#253344" }}>GHG Accounting</div>
                <div className="plan-details">Basic Plan</div>
              </div>
              <div className="module-details">
                <div style={{ color: "#253344" }}>GHG Accounting</div>
                <div className="plan-details">Basic Plan</div>
              </div>
            </div>

            <div className="pricing-details">
              <div className="module-price-details">
                <div style={{color: '#253344'}}>GHG Accouting</div>
                <div style={{color: '#7B99BE'}}>17,18,1887</div>
              </div>
              <div className="module-price-details">
                <div style={{color: '#253344'}}>GHG Accouting</div>
                <div style={{color: '#7B99BE'}}>17,18,1887</div>
              </div>
              <div className="module-price-details">
                <div style={{color: '#253344'}}>GHG Accouting</div>
                <div style={{color: '#7B99BE'}}>17,18,1887</div>
              </div>
              <div className="module-price-details">
                <div style={{color: '#253344'}}>GHG Accouting</div>
                <div style={{color: '#7B99BE'}}>17,18,1887</div>
              </div>
              <div className="module-price-details">
                <div style={{color: '#253344'}}>GHG Accouting</div>
                <div style={{color: '#7B99BE'}}>17,18,1887</div>
              </div>
              <div className="module-price-details">
                <div style={{color: '#253344'}}>Decarbonisation Pathways</div>
                <div style={{color: '#7B99BE'}}>17,18,1887</div>
              </div>
            </div>

            <div className="bottom-details-container">
              <div className="title-text">Payment Method: <span className="value">Cash</span></div>
              <div className="title-text">
                Total Billed Amount: <span className="value">23,7567</span>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="contact-bottom">
          <button onClick={() => navigate('/contact-support')}>Contact Support</button>
        </div>
    </div>
  );
};

export default UserProfile;
