import "./CheckboxSelect.scss";

const accessTypes = [
  {
    name: "Read Only",
    value: "read",
  },
  {
    name: "Read/Write",
    value: "write",
  },
];

const CheckboxSelect = ({
  entries,
  filters,
  setFilters,
  openModule,
  setOpenModule,
}: {
  entries: any;
  filters: any;
  setFilters: any;
  openModule: any;
  setOpenModule: any;
}) => {
  return (
    <div className="checkbox-select-container">
      {entries.map((item: any) => (
        <div key={item.value} className="checkbox-inner-container">
          <div className="checkbox-item">
            <input
              style={{ cursor: "pointer" }}
              type="checkbox"
              checked={filters[item.value].isChecked}
              onChange={() =>
                setFilters((prev: any) => ({
                  ...prev,
                  [item.value]: {
                    ...prev[item.value],
                    isChecked: !prev[item.value].isChecked,
                    ...(prev[item.value].isChecked ? {read: false, write: false} : {}),
                  },
                }))
              }
            />
            <div
              role="button"
              className="module-name"
              onClick={() =>
                setOpenModule((prev: any) => ({
                  ...prev,
                  [item.value]: !prev[item.value],
                }))
              }
            >
              {item.name}
            </div>
          </div>

          {openModule[item.value] ? (
            <div className="access-types">
              {accessTypes.map((accessType) => (
                <div className="checkbox-item" key={accessType.value}>
                  <input
                    style={{ cursor: "pointer" }}
                    type="checkbox"
                    disabled={!filters[item.value].isChecked}
                    checked={filters[item.value][accessType.value]}
                    onChange={() =>
                      setFilters((prev: any) => ({
                        ...prev,
                        [item.value]: {
                          ...prev[item.value],
                          [accessType.value]:
                            !prev[item.value][accessType.value],
                        },
                      }))
                    }
                  />
                  <div>{accessType.name}</div>
                </div>
              ))}
            </div>
          ) : null}
        </div>
      ))}
    </div>
  );
};

export default CheckboxSelect;
