import { useEffect, useState, useImperativeHandle, useCallback } from "react";
import api from "../../../../api";
import { toast } from "react-toastify";

type Module = {
    moduleName: string,
    modulePlan: string
}

type Sector = {
    sector: string,
    items?: Module[]
}

type PurchaseDetails = {
    readCapacity: number,
    writeCapacity: number,
    modules?: Sector[]
}

const useGetPurchaseDetails = () => {
  const [purchaseDetails, setPurchaseDetails] = useState<PurchaseDetails>({
    readCapacity: 0,
    writeCapacity: 0,
  });
  const [isPurchaseVisible, setPurchaseVisible] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handlepurchasebutton = () => {
    setPurchaseVisible((prev) => !prev);
  };
  

  const getPurchaseDetails = useCallback(async () => {
    try {
      setLoading(true);
      const response = await fetch(api.config.getModules(), api.http.get());
      const result = await api.afterFetchHandlers.parseContent(
        response,
        api.http.get()
      );
      
      setPurchaseDetails(result.moduleIds || []);
    } catch (error: any) {
      toast.error(error?.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  }, [setPurchaseDetails]);

  useEffect(() => {
    getPurchaseDetails();
  }, [getPurchaseDetails]);

  return {
    purchaseDetails,
    handlepurchasebutton,
    loading,
    isPurchaseVisible,
    setPurchaseVisible
  };
};

export default useGetPurchaseDetails;
