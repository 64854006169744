import React, { useState } from "react";
import "./MobileHeader.scss";
import CompanyLogo from "../../../../assets/svg/company-logo.svg";
import { ReactComponent as ArrowTop } from "../../../../assets/svg/ArrowTop.svg";
import { ReactComponent as ArrowDown } from "../../../../assets/svg/ArrowDown.svg";
import signOut from "../../../../assets/svg/userProfileAsset/SignOut.svg";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutDetails } from "../../../../redux/slice/userAuthSlice/Login/userLoginSlice";
import { AppDispatch } from "../../../../redux/store";
import headerProductDetails from "../../../../utils/configurations/headerProductDetails";
import Flag from "../../../../assets/svg/usa-flag.svg";
import profileSections from "../../UserProfile/profileSections";

type ProfileSection = {
  title: string;
  img: any;
  pathUrl: string;
};

const MobileHeader = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();

  const userLogin = useSelector((state: any) => state.userLogin || {});

  const navigate = useNavigate();

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const toggleDropdown = (e: any) => {
    e.stopPropagation();
    setDropdown(!dropdown);
  };

  const path = location.pathname;

  const AuthHeader = [
    "/login",
    "/signup",
    "/accountverify",
    "/changepassword",
    "/resetpassword",
  ];

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleProfileLogout = () => {
    dispatch(logoutDetails(null)).then((result: any) => {
      localStorage.clear();
      navigate("/login");
    });
  };

  const userRole = userLogin.response?.result?.data?.payload?.user_role;

  return (
    <>
      {!AuthHeader.includes(path) && (
        <div className="MobileHeader">
          <div className="mobileheaderleft">
            <div className="logo">
              <img className="mobileLogo" src={CompanyLogo} alt="" />
            </div>
            <div className="mobileheadermiddle">
              <div className="sustain">Sustainability</div>
              <div className="market">Marketplace</div>
            </div>
          </div>
          <div className="mobileheaderright">
            <div className="burger-menu">
              <div
                className={`hamburger ${isMenuOpen ? "open" : ""} `}
                onClick={toggleMenu}
              >
                {isMenuOpen ? (
                  <div className="cross-btn" />
                ) : (
                  <>
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                  </>
                )}

                {isMenuOpen && (
                  <div className="menu">
                    <div className="menu-heading">
                      <div className="menu-content">
                        {!["employee", "investee"].includes(userRole) ? (
                          <Link
                            to="/"
                            className={
                              path === "/" ? "tagline active-link" : "tagline"
                            }
                          >
                            Build Your Platform
                          </Link>
                        ) : null}

                        <div className="header-dropdown">
                          <h3 className="module">Products</h3>

                          <div className="arrow" onClick={toggleDropdown}>
                            {dropdown ? <ArrowTop /> : <ArrowDown />}
                          </div>
                        </div>

                        {dropdown && (
                          <div className="dropdown-content">
                            {headerProductDetails.map((item: any, index) => (
                              <div
                                className="dropdown-items"
                                key={item.handleTitleClick}
                                onClick={() =>
                                  navigate("/readmore", { state: index })
                                }
                              >
                                <img src={item.imgSrc} alt={item.title} />
                                {item.title}
                              </div>
                            ))}
                          </div>
                        )}

                        {userRole !== "user" ? (
                          <Link
                            to="/sectors"
                            className={
                              path === "/sectors"
                                ? "tagline active-link"
                                : "tagline"
                            }
                          >
                            Your Platforms
                          </Link>
                        ) : null}

                        <div
                          className="language-selector"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <select>
                            <option className="item">
                              <img src={Flag} width={20} height={20} />
                              <div>English</div>
                            </option>
                          </select>
                        </div>

                        <div className="menu-items">
                          {profileSections.map(
                            ({ title, pathUrl, img }: ProfileSection) => {
                              if (
                                userRole === "user" &&
                                pathUrl === "/orderhistory"
                              ) {
                                return null;
                              }
                              return (
                                <div
                                  className="menu-dropdown-items"
                                  onClick={() => navigate(pathUrl)}
                                >
                                  <img src={img} alt={title} />
                                  {title}
                                </div>
                              );
                            }
                          )}

                          <div
                            className="menu-dropdown-items"
                            onClick={handleProfileLogout}
                            role="button"
                          >
                            <img src={signOut} alt="sign-out" />
                            Sign Out
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MobileHeader;
