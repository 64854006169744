import React, { ChangeEvent, useState } from "react";
import "./NewEmployeePopUp.scss";
import Close from "../../../assets/svg/Close";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import ButtonWithSpinner from "../../components/Utils/ButtonWithSpinner/ButtonWithSpinner";
// defining the data format for cart
interface Module {
  moduleName: string;
  accessType: string;
}
// defining the data format for the new employee
interface User {
  firstName: string;
  lastName: string;
  role: string;
  email: string;
  module_access: Module[];
}

interface ErrorType {
  firstName: boolean;
  lastName: boolean;
  role: boolean;
  email: boolean;
}

//component definition with the props
const NewEmployeePopUp = ({
  popupRef,
  details,
  setDetails,
  edit,
  addOrUpdateEmployee,
  onCloseEmployeePopup
}: {
  popupRef: React.RefObject<HTMLDivElement>,
  details: User;
  setDetails: any;
  edit: boolean;
  addOrUpdateEmployee: any;
  onCloseEmployeePopup: any;
}) => {

  const location = useLocation();
  const sector = location.state.sector;
  
  const cart = location.state.cart;
  const cartDetails = cart.items;

  const [loading, setLoading] = useState<boolean>(false);

  const [error, setError] = useState<ErrorType>({
    firstName: false,
    lastName: false,
    role: false,
    email: false
  });

  const handleRemoveModule = (index:number) =>{
    const updateModule = details.module_access.filter((_,idx)=> idx!=index )
    setDetails((prevData: any) => ({
      ...prevData,
      module_access: updateModule,
    }));
  }

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    setDetails({ ...details, [name]: value });
    setError((prev) => ({ ...prev, [name]: !value }));
  };

  const handleNewFieldInput = (
    e: ChangeEvent<HTMLSelectElement>,
    index: any
  ) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    if (edit) {
      const lastItemIndex = details.module_access.length - 1;
      const newArray = [...details.module_access];
      newArray[lastItemIndex] = {
        ...newArray[lastItemIndex],
        [name]: value,
      };
      setDetails((prevState: any) => ({
        ...prevState,
        module_access: newArray,
      }));
    } else {
      const updatedData = details.module_access.map((item, idx) =>
        idx === index ? { ...item, [name]: value } : item
      );
      setDetails((prevState: any) => ({
        ...prevState,
        module_access: updatedData,
      }));
    }

  };

  const addNewField = () => {
    setDetails((prevData: any) => ({
      ...prevData,
      module_access: [
        ...prevData.module_access,
        { moduleName: "", accessType: "" },
      ],
    }));
  };
  // const handleRemove = (index:number) => {};
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    const src = edit ? "edit" : "new";

    if (!details.firstName || !details.lastName || !details.role || !details.email) {
      setError({
        firstName: !details.firstName,
        lastName: !details.lastName,
        role: !details.role,
        email: !details.email,
      });
      return;
    }

    if(details.module_access.length === 0) {
      toast.error('Module details are required');
      return;
    }

    let emptyFields = false;

    details.module_access.forEach((module) => {
      if(!module.moduleName || !module.accessType) {
        emptyFields = true;
        return;
      }
    });

    if(emptyFields) {
      toast.error('Please fill all module details');
      return;
    }

    setLoading(true);
    await addOrUpdateEmployee(src, details);
    setLoading(false);
  };

  return (
    <div className="addNewUserPopup" ref={popupRef}>
      <div className="popUpHeader">
        <div className="heading">{edit ? 'Update details' : 'Add New Employee'}</div>
        <button className="popup-closeButton" onClick={onCloseEmployeePopup}>
          <Close color={"#000"} width={16} height={16} />
        </button>
      </div>
      <div className="productplanContainer">
        <form className="newEmployeeUserForm" onSubmit={handleSubmit}>
          <div className="row">
            <div className="form-group">
              <label className="label" htmlFor="fname">First Name <span style={{color:'#f00'}}>*</span></label>
              <input
                type="text"
                className="input"
                name="firstName"
                onChange={(e) => handleInput(e)}
                value={details.firstName}
                placeholder="Enter First Name"
              />
              {error.firstName ? <p className="error-text">Enter First Name</p> : null}
            </div>
            <div className="form-group">
              <label className="label" htmlFor="lname">Last Name <span style={{color:'#f00'}}>*</span></label>
              <input
                type="text"
                className="input"
                name="lastName"
                onChange={(e) => handleInput(e)}
                value={details.lastName}
                placeholder="Enter Last Name"
              />
              {error.lastName ? <p className="error-text">Enter Last Name</p> : null}
            </div>
          </div>

          <div className="form-group">
              <label className="label">Role <span style={{color:'#f00'}}>*</span></label>
              <input
                type="text"
                className="input"
                name="role"
                onChange={(e) => handleInput(e)}
                value={details.role}
                placeholder="Enter Role"
              />
              {error.role ? <p className="error-text">Enter Role</p> : null}
            </div>

          <div className="form-group">
            <label className="label">Email <span style={{color:'#f00'}}>*</span></label>
            <input
              type="email"
              className="input"
              name="email"
              onChange={(e) => handleInput(e)}
              value={details.email}
              placeholder="Enter Email"
            />
            {error.email ? <p className="error-text">Enter Email</p> : null}
          </div>
          <h5 className="moduleHeader">Modules <span style={{color:'#f00'}}>*</span></h5>
          {details.module_access.map((field, index) => (
            <div key={index} className="row">
              <div className="form-group">
                <label className="label">Module {index + 1} <span style={{color:'#f00'}}>*</span></label>
                <select
                  id="moduleName"
                  name="moduleName"
                  value={field.moduleName}
                  onChange={(e) => {
                    handleNewFieldInput(e, index);
                  }}
                >
                  <option value="">Select...</option>
                  {cartDetails?.map((item: any, index: number) => (
                    <option key={index} value={item.moduleName}>
                      {item.moduleName}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label className="label">Access Types <span style={{color: '#f00'}}>*</span></label>
                <select
                  id="accessType"
                  name="accessType"
                  value={field.accessType}
                  onChange={(e) => {
                    handleNewFieldInput(e, index);
                  }}
                >
                  <option value="">Select...</option>
                  <option value="read">Read Only</option>
                  <option value="write">Read/Write</option>
                </select>
              </div>

              <button type="button" className="popup-closeButton" onClick={() =>handleRemoveModule(index)}>
              <div className="cross-mark"></div>
              </button>
            </div>
          ))}
          <div className="buttonPart addModuleButton">
            <button
              type="button"
              onClick={addNewField}
              className="loginbutton card-button "
            >
              + Add Module
            </button>
          </div>
          <div className="buttonPart addUserButton">

            <ButtonWithSpinner isLoading={loading} type="submit" className="loginbutton card-button">
            {edit ? 'Save' : 'Add Employee'}
            </ButtonWithSpinner>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewEmployeePopUp;
