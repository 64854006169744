import React, { useEffect, useState } from "react";
import "./Sectors.scss"; // Create a CSS file for styling the popup.
import FirstSector from "../../../assets/svg/firstSector.svg";
import Arrow from "../../../assets/svg/arrow-right.svg";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getCartDetails, getModuleDetails } from "../../../redux/slice/cartSlice/cartApiSlice";
import { AppDispatch } from "../../../redux/store";
import { toast } from "react-toastify";
import TransparentLoader from "../../components/Utils/Loader/TransparentLoader";
interface Module {
  moduleName: string;
  modulePlan: string;
}
interface Cart {
  sector: string;
  items: Module[];
}
const Sectors = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [sector, setSector] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  //get the cart details for the particular sector
  useEffect(() => {

    const fetchData = async () => {
      setLoading(true);
      try {
        const result = await dispatch(getModuleDetails(null));
        setSector(result.payload?.data?.map((cart: Cart) => cart.sector));
      } catch (error: any) {
        toast.error(error?.message || 'Something went wrong');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if(loading) return <TransparentLoader />;

  return (
    <div className="sectorContainer">
      {sector?.map((sector: any, index: number) => {
        return (
          sector && (
            <Link
              key={index}
              className="manage-users"
              to={{
                pathname: "/continueyourjourney",
              }}
              state={{ sector: sector }}
            >
              <div className="cardContainer">
                <img src={FirstSector} alt="x" />
                <div className="sectorName">Net Zero {sector}</div>
                <div className="sectorDescription">
                  Prevent or reduce carbon from being emitted into the
                  atmosphere
                </div>
                <img className="arrow" src={Arrow} alt="x" />
              </div>
            </Link>
          )
        );
      })}
    </div>
  );
};

export default Sectors;
