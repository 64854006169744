import { useEffect, useState, useImperativeHandle, useCallback } from "react";
import api from "../../../../../api";
import { toast } from "react-toastify";

interface FormData {
    firstName?: string;
    lastName?: string;
    companyName?: string;
    password: string;
    newPassword: string;
    confirmPassword: "";
  }

const useGetUserInfo = () => {
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState<FormData>({
    firstName: "",
    lastName: "",
    companyName: "",
    password: "",
    newPassword: "",
    confirmPassword: "",
  });

  const getUserInfo = useCallback(async () => {
    try {
      setLoading(true);
      const response = await fetch(api.config.getUserInfo(), api.http.get());
      const result = await api.afterFetchHandlers.parseContent(
        response,
        api.http.get()
      );

      setFormData((prev) => ({
        ...prev,
        firstName: result.firstName,
        lastName: result.lastName,
        companyName: result.company_name
      }));
    
    } catch (error: any) {
      toast.error(error?.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  }, [setFormData]);

  useEffect(() => {
    getUserInfo();
  }, []);

  return {
    formData,
    loading,
    setFormData,
    getUserInfo,
  };
};

export default useGetUserInfo;
