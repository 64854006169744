import api from "../../../../api";
import { toast } from "react-toastify";
import { useState, useEffect, useRef } from "react";

interface Module {
  moduleName: string;
  accessType: string;
}

type Employee = {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  module_access: Module[];
};

type Investee = {
  firstName: string;
  lastName: string;
  email: string;
  company_name: string;
  isin: string;
  onboarded_status?: string;
};


const useHandleUser = () => {
  const initialEmployeeData: Employee = {
    firstName: "",
    lastName: "",
    role: "",
    email: "",
    module_access: [{ moduleName: "", accessType: "" }],
  };

  const initialInvesteeData: Investee = {
    firstName: "",
    lastName: "",
    email: "",
    company_name: "",
    isin: "",
    onboarded_status: "",
  };

  const [employeeDetails, setEmployeeDetails] =
    useState<any>(initialEmployeeData);
  const [investeeDetails, setInvesteeDetails] =
    useState<any>(initialInvesteeData);
  const [editEmployee, setEditEmployee] = useState<boolean>(false);
  const [editInvestee, setEditInvestee] = useState<boolean>(false);
  const [activeSubTab, setActiveSubTab] = useState<string | null>("subtab1");
  const [activeTab, setActiveTab] = useState<number | null>(1);

  const [showEmployeePopup, setShowEmployeePopup] = useState(false);
  const [showInvesteePopup, setShowInvesteePopup] = useState(false);

  const [uploadPopup, setUploadPopup] = useState<string>("");

  const popupRef = useRef<HTMLDivElement>(null);
  const employeeRef = useRef<{getEmployees : Function}>(null);
  const investeeRef = useRef<{getInvestees: Function}>(null);

  const onCloseEmployeePopup = () => {
    setShowEmployeePopup(false);
    setEditEmployee(false);
    setEmployeeDetails(initialEmployeeData);
  }

  const onCloseInvesteePopup = () => {
    setShowInvesteePopup(false);
    setEditInvestee(false);
    setInvesteeDetails(initialInvesteeData);
  }

  const outsidepopup = (event: any) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      onCloseEmployeePopup();
      onCloseInvesteePopup();
      setUploadPopup("");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", outsidepopup);
    return () => {
      document.removeEventListener("mousedown", outsidepopup);
    };
  }, []);

  const handleEmployeeEditClick = (employee: Employee) => {
    setEditEmployee(true);
    setShowEmployeePopup(true);
    setEmployeeDetails(employee);
  };
  // handler for investee edit click
  const handleInvesteeEditClick = (investee: Investee) => {
    setEditInvestee(true);
    setShowInvesteePopup(true);
    setInvesteeDetails(investee);
  };

  const addOrUpdateEmployee = async (
    src: string,
    { firstName, lastName, email, role, module_access }: Employee
  ) => {
    try {
      const apiEndPoint =
        src === "edit" ? api.config.updateemployee() : api.config.addemployee();

      const response = await fetch(
        apiEndPoint,
        api.http.post({ fname: firstName, lname: lastName, email, role, module_access })
      );

      api.afterFetchHandlers.checkStatus(response);

      onCloseEmployeePopup();

      const toastSuccessText = src === 'edit' ? 'Updated successfully' : 'Employee added successfully';

      toast.success(toastSuccessText);

      employeeRef.current?.getEmployees();

    } catch (error: any) {

      const result = await error.json();

      toast.error(result?.error || "Something went wrong");
    }
  };

  const addOrUpdateInvestee = async (
    src: string,
    { firstName, lastName, email, company_name, isin }: Investee
  ) => {
    try {
      const apiEndPoint =
        src === "edit" ? api.config.updateinvestee() : api.config.addinvestee();

      const response = await fetch(
        apiEndPoint,
        api.http.post({ fname: firstName, lname: lastName, email, company_name, isin })
      );

      api.afterFetchHandlers.checkStatus(response);

      onCloseInvesteePopup();

      const toastSuccessText = src === 'edit' ? 'Updated successfully' : 'Investee added successfully';

      toast.success(toastSuccessText);

      investeeRef.current?.getInvestees();

    } catch (error: any) {
      const result = await error.json();
      toast.error(result?.error || "Something went wrong");
    }
  };

  const handleRemoveClick = async (email: string, src: string) => {
    try {
      const response = await fetch(
        api.config.deleteuser(),
        api.http.post({ email })
      );
      await api.afterFetchHandlers.parseContent(response, api.http.post(email));

      toast.success('Removed successfully');

      if(src === 'employee') {
        employeeRef.current?.getEmployees();
        return;
      }
      investeeRef.current?.getInvestees();

    } catch (error: any) {
      toast.error(error?.message || "Something went wrong");
    }
  };

  useEffect(() => {
    setUploadPopup("");
  }, [activeSubTab]);

  return {
    popupRef,
    employeeRef,
    investeeRef,
    activeTab,
    setActiveTab,
    activeSubTab,
    setActiveSubTab,
    employeeDetails,
    investeeDetails,
    setEmployeeDetails,
    setInvesteeDetails,
    editEmployee,
    editInvestee,
    addOrUpdateEmployee,
    addOrUpdateInvestee,
    handleRemoveClick,
    showEmployeePopup,
    showInvesteePopup,
    setShowEmployeePopup,
    setShowInvesteePopup,
    uploadPopup,
    setUploadPopup,
    handleEmployeeEditClick,
    handleInvesteeEditClick,
    onCloseEmployeePopup,
    onCloseInvesteePopup
  };
};

export default useHandleUser;
