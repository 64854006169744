import gfg from "../../assets/svg/mobilegfg.svg";
import target from "../../assets/svg/MobileTargett.svg";
import climate from "../../assets/svg/MobileClimate.svg";
import reporting from "../../assets/svg/Mobilereport.svg";
import transition from "../../assets/svg/MobileTransition.svg";
import carbon from "../../assets/svg/MobileCarbon.svg";
import CarbonBonds from "../../assets/svg/carbonBonds.svg";

const headerProductDetails = [
  {
    title: "GHG Accounting",
    imgSrc: gfg,
  },
  {
    title: "Target Setting",
    imgSrc: target,
  },
  {
    title: "Climate Scenario Analysis",
    imgSrc: climate,
  },
  {
    title: "Decarbonisaiton Pathways",
    imgSrc: CarbonBonds,
  },
  {
    title: "Transition Financing",
    imgSrc: transition,
  },
  {
    title: "Marketplace",
    imgSrc: carbon,
  },
  {
    title: "Reporting",
    imgSrc: reporting,
  },
];

export default headerProductDetails;
