import './TransparentLoader.scss';
import transparentLoader from "../../../../assets/animations/loaders/transparent_loader.gif";

const TransparentLoader = () => {
  return (
    <div className="loader-container"><img className="transparent-loader" src={transparentLoader} /></div>
  );
};

export default TransparentLoader;
