import React, { ChangeEvent, useState } from "react";
import "./NewInvesteePopUp.scss";
import Close from "../../../assets/svg/Close";
import ButtonWithSpinner from "../../components/Utils/ButtonWithSpinner/ButtonWithSpinner";
interface User {
  firstName: string;
  lastName: string;
  email: string;
  company_name: string;
  isin: string;
  onboarded_status: string;
}

interface ErrorType {
  firstName: boolean;
  lastName: boolean;
  email: boolean;
  company_name: boolean;
  isin: boolean;
}

const NewInvesteePopUp = ({
  popupRef,
  details,
  setDetails,
  edit,
  addOrUpdateInvestee,
  onCloseInvesteePopup
}: {
  popupRef: React.RefObject<HTMLDivElement>;
  details: User;
  setDetails: any;
  edit: boolean;
  addOrUpdateInvestee: any;
  onCloseInvesteePopup: any;
}) => {

  const [loading, setLoading] = useState<boolean>(false);

  const [error, setError] = useState<ErrorType>({
    firstName: false,
    lastName: false,
    email: false,
    company_name: false,
    isin: false,
  });

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    setDetails({ ...details, [name]: value });

    setError((prev) => ({ ...prev, [name]: !value }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const src = edit ? "edit" : "new";

    if (
      !details.firstName ||
      !details.lastName ||
      !details.company_name ||
      !details.email ||
      !details.isin
    ) {
      setError({
        firstName: !details.firstName,
        lastName: !details.lastName,
        company_name: !details.company_name,
        isin: !details.isin,
        email: !details.email,
      });
      return;
    }

    setLoading(true);
    await addOrUpdateInvestee(src, details);
    setLoading(false);
  };

  return (
    <div className="addNewUserPopup" ref={popupRef}>
      <div className="popUpHeader">
        <div className="heading">
          {edit ? "Update details" : "Add New Investee"}
        </div>
        <button
          className="popup-closeButton"
          onClick={onCloseInvesteePopup}
        >
          <Close color={"#000"} width={16} height={16} />
        </button>
      </div>
      <div className="productplanContainer">
        <form className="newUserForm" onSubmit={(e) => handleSubmit(e)}>
          <div className="row">
            <div className="form-group">
              <label className="label">
                First Name <span style={{ color: "#f00" }}>*</span>
              </label>
              <input
                type="text"
                className="input"
                name="firstName"
                onChange={(e) => handleInput(e)}
                value={details.firstName}
                placeholder="Enter First Name"
              />
              {error.firstName ? (
                <p className="error-text">Enter First Name</p>
              ) : null}
            </div>
            <div className="form-group">
              <label className="label">
                Last Name <span style={{ color: "#f00" }}>*</span>
              </label>
              <input
                type="text"
                className="input"
                name="lastName"
                onChange={(e) => handleInput(e)}
                value={details.lastName}
                placeholder="Enter Last Name"
              />

              {error.lastName ? (
                <p className="error-text">Enter Last Name</p>
              ) : null}
            </div>
          </div>

          <div className="form-group">
            <label className="label">
              Company <span style={{ color: "#f00" }}>*</span>
            </label>
            <input
              type="text"
              className="input"
              name="company_name"
              onChange={(e) => handleInput(e)}
              value={details.company_name}
              placeholder="Enter Company Name"
            />
            {error.company_name ? (
              <p className="error-text">Enter Company Name</p>
            ) : null}
          </div>

          <div className="form-group">
            <label className="label">
              Email <span style={{ color: "#f00" }}>*</span>
            </label>
            <input
              type="email"
              className="input"
              name="email"
              onChange={(e) => handleInput(e)}
              value={details.email}
              placeholder="Enter Email"
            />
            {error.email ? <p className="error-text">Enter Email</p> : null}
          </div>
          <div className="form-group">
            <label className="label">
              ISIN <span style={{ color: "#f00" }}>*</span>
            </label>
            <input
              type="text"
              className="input"
              name="isin"
              onChange={(e) => handleInput(e)}
              value={details.isin}
              placeholder="Enter ISIN"
            />
            {error.isin ? <p className="error-text">Enter ISIN</p> : null}
          </div>
          <div className="buttonPart addUserButton">
            <ButtonWithSpinner
              isLoading={loading}
              type="submit"
              className="loginbutton card-button"
            >
              {edit ? "Save" : "Add Investee"}
            </ButtonWithSpinner>
          </div>
        </form>
        <hr />
      </div>
    </div>
  );
};

export default NewInvesteePopUp;
