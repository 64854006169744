import React from "react";
import "./Addcart.scss";

interface cartprops {
  addedcart: any;
  editcart: any;
  removeItem: string;
  handleCartDelete: any;
}

const Addcart: React.FC<cartprops> = ({
  addedcart,
  editcart,
  removeItem,
  handleCartDelete,
}) => {
  return (
    <div className="parent-cart">
      {addedcart &&
        addedcart?.items?.map((item: any, index: number) => {
          const isGettingRemoved = item.moduleName === removeItem;

          return (
            <div key={index} className="cart">
              <div className="title">
                {item.moduleName}-{item.modulePlan}
                <span>
                  <button
                    onClick={() => handleCartDelete(item)}
                    className={isGettingRemoved ? "button-loader" : ""}
                  >
                    {item.moduleName === removeItem ? (
                      <div className="spinner" />
                    ) : (
                      "X"
                    )}
                  </button>
                </span>
              </div>
            </div>
          );
        })}
      {editcart &&
        editcart?.map((item: any, index: number) => {
          return (
            <div key={index} className="cart">
              <div className="title">
                {item.moduleName}-{item.modulePlan}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default Addcart;
