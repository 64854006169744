import "./App.scss";
import { Route, Routes } from "react-router-dom";
import ToastWrapper from "./Pages/components/ToastWrapper/ToastWrapper";
import { useLocation } from "react-router-dom";

import Layout from "./Pages/components/Utils/Layout/Layout";
import {
  protectedRoutes,
  openRoutes,
  publicRoutes,
  employeeAllowedRoutes,
} from "./Routes/routeConfig";

import { Outlet, Navigate } from "react-router-dom";

const ProtectedRouteLayout = () => {
  const token = localStorage.getItem("accessToken");
  const userRole = localStorage.getItem("user_role");

  const location = useLocation();
  const currentPath = location.pathname;

  if (
    token &&
    userRole === "employee" &&
    !employeeAllowedRoutes.includes(currentPath)
  ) {
    return <Navigate to="/sectors" />;
  }
  return token ? <Outlet /> : <Navigate to="/login" />;
};

const PartialProtectedRouteLayout = () => {
  const token = localStorage.getItem("accessToken");
  return token ? <Navigate to="/" /> : <Outlet />;
};

function App() {
  return (
    <div className="App">
      <ToastWrapper />
      <Routes>
        {/* Open routes only access by non logged In users */}
        <Route element={<PartialProtectedRouteLayout />}>
          {openRoutes.map((routeElement) => (
            <Route
              key={routeElement.path}
              path={routeElement.path}
              element={
                routeElement.layout ? (
                  <Layout>{routeElement.element}</Layout>
                ) : (
                  routeElement.element
                )
              }
            />
          ))}
        </Route>

        {/*  Protected Routes */}
        <Route element={<ProtectedRouteLayout />}>
          {protectedRoutes.map((routeElement) => (
            <Route
              key={routeElement.path}
              path={routeElement.path}
              element={
                routeElement.layout ? (
                  <Layout>{routeElement.element}</Layout>
                ) : (
                  routeElement.element
                )
              }
            />
          ))}
        </Route>

        {/* Public Routes */}
        {publicRoutes.map((routeElement) => (
          <Route
            key={routeElement.path}
            path={routeElement.path}
            element={
              routeElement.layout ? (
                <Layout>{routeElement.element}</Layout>
              ) : (
                routeElement.element
              )
            }
          />
        ))}
      </Routes>
    </div>
  );
}
export default App;
