import React from "react";
import Modules from "../Pages/components/Home/Modules/Modules";
import Signup from "../Pages/components/Signup/Signup";
import SubModules from "../Pages/components/Home/SubModules/SubModules";
import Accountverify from "../Pages/components/AccountVerify/Accountverify";
import ProductPlatform from "../Pages/ProductPlatform/ProductPlatform";
import Sectors from "../Pages/ProductPlatform/Sectors/Sectors";
import ReadMoreCategory from "../Pages/ProductPlatform/ReadMore/ReadMoreCategory";
import ManageUser from "../Pages/Phase2/ManageUser/ManageUser";
import Submission from "../Pages/ProductPlatform/Submission/Submission";
import ClimateJourney from "../Pages/Phase2/ClimateJourney/ClimateJourney";
import Thankyou from "../Pages/ProductPlatform/Submission/ThankYou/ThankYou";
import ResetPassword from "../Pages/components/ResetPassword/ResetPassword";
import ChangePassword from "../Pages/components/ChangePassword/ChangePassword";
import OrderHistory from "../Pages/components/UserProfile/OrderHistory/OrderHistory";
import AccountSetting from "../Pages/components/UserProfile/AccountSetting/AccountSetting";
import ContactSupport from "../Pages/Phase2/ContactSupport/ContactSupport";
import NotFound from "../Pages/components/NotFound/NotFound";
import Login from "../Pages/components/Login/Login";

export type routType = {
  path: string;
  element: React.ReactNode;
  layout?: boolean;
};

// These routes only accessd by Loggedin Users
const protectedRoutes: routType[] = [
  {
    path: "/",
    element: <Modules />,
    layout: true,
  },
  {
    path: "/home",
    element: <Modules />,
    layout: true,
  },
  {
    path: "/persona",
    element: <SubModules />,
    layout: true,
  },
  {
    path: "/persona/:platform",
    element: <ProductPlatform />,
    layout: true,
  },
  {
    path: "/product-platform",
    element: <ProductPlatform />,
    layout: true,
  },
  {
    path: "/sectors",
    element: <Sectors />,
    layout: true,
  },
  {
    path: "/readmore",
    element: <ReadMoreCategory />,
    layout: true,
  },
  {
    path: "/manageusers",
    element: <ManageUser />,
    layout: true,
  },
  {
    path: "/submission",
    element: <Submission />,
    layout: true,
  },
  {
    path: "/continueyourjourney",
    element: <ClimateJourney />,
    layout: true,
  },
  {
    path: "/thankyou",
    element: <Thankyou />,
    layout: true,
  },
  {
    path: "/orderhistory",
    element: <OrderHistory />,
    layout: true,
  },
  {
    path: "/profile",
    element: <AccountSetting />,
    layout: true,
  },
  {
    path: "/contact-support",
    element: <ContactSupport />,
    layout: true,
  },
  {
    path: "*",
    element: <NotFound />,
    layout: true,
  },
];

// These routes can only accessed by non loggedin Users.
const openRoutes: routType[] = [
  {
    path: "/login",
    element: <Login />,
    layout: false,
  },
  {
    path: "/signup",
    element: <Signup />,
    layout: false,
  },
  {
    path: "/accountverify",
    element: <Accountverify />,
    layout: false,
  },
  {
    path: "/resetpassword",
    element: <ResetPassword />,
    layout: false,
  },
  {
    path: "/changepassword",
    element: <ChangePassword />,
    layout: false,
  },
];

// These routes can access by any users.
const publicRoutes: routType[] = [
  {
    path: "*",
    element: <NotFound />,
    layout: false,
  },
];

const employeeAllowedRoutes = [
  "/sectors",
  "/readmore",
  "/manageusers",
  "/continueyourjourney",
  "/profile",
  "/orderhistory",
  "/contact-support",
];

export { protectedRoutes, openRoutes, publicRoutes, employeeAllowedRoutes };
