import React, { ChangeEvent, FormEvent, useState } from "react";
import { useDispatch } from "react-redux";
import login from "../../../assets/svg/login.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AppDispatch } from "../../../redux/store";
import { changePasswordapi } from "../../../redux/slice/userAuthSlice/Changepassword/changePasswordSlice";
import "./ChangePassword.scss";
import CompanyLogo from "../../../assets/svg/company-logo.svg";
import { confirmemployeeonboarddetails } from "../../../redux/slice/manageUserSlice/ConfirmOnboardingSlice";
import { toast } from "react-toastify";
import eye from "../../../assets/svg/eye.svg";
import eyeSlash from "../../../assets/svg/eyeslash.svg";
import { useSelector } from "react-redux";

//data format for change password page
interface FormData {
  email: string;
  password: string;
  newPassword: string;
  confirmPassword: string;
}

interface ValidationData {
  upperCase: boolean;
  lowerCase: boolean;
  specialCase: boolean;
  length: boolean;
  number: boolean;
}

const ChangePassword: React.FC = () => {
  // variable and hooks declaration
  const dispatch = useDispatch<AppDispatch>();
  const { changePassword, confirmEmployeeOnboardDetails } = useSelector((state: any) => ({
    changePassword: state.changePassword,
    confirmEmployeeOnboardDetails: state.confirmEmployeeOnboardDetails
  }));

  const isLoading = changePassword.isLoading || confirmEmployeeOnboardDetails.isLoading;

  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState("");
  const [showOldPassword, setShowOldPassword] = useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [formData, setFormData] = useState<FormData>({
    email: "",
    password: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [validation, setValidation] = useState<ValidationData>({
    upperCase: false,
    lowerCase: false,
    specialCase: false,
    length: false,
    number: false,
  });

  const userType = location.state;

  // handler for the submit event
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (Object.values(validation).some(value => !value)) {
      toast.error("Set password according to given rules");
      return;
    }

    if(formData.newPassword !== formData.confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }

    //dispatching action to redux store to make API call for change password
    if (userType == null) {
      dispatch(changePasswordapi(formData)).then((res) => {
        if (res.payload?.result?.success == true) {
          toast.success("Password changed successfully!");
          setError("");
          navigate("/login");
        }
        if (res.payload?.error) {
          setError(res.payload?.error);
        }
      });
    } else {
      dispatch(confirmemployeeonboarddetails(formData)).then((res) => {
        if (res.payload?.success == true) {
          toast.success("Password changed successfully!");
          setError("");
          navigate("/login");
        }
        if (res.payload?.error) {
          setError(res.payload?.error);
        }
      });
    }
  };

  // handler for change in input fields
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const { name, value } = e.target;

    if (name == "newPassword") {
      setValidation({
        length: value.length >= 8,
        upperCase: /[A-Z]/.test(value),
        lowerCase: /[a-z]/.test(value),
        number: /[0-9]/.test(value),
        specialCase: /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(value)
      });
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div className="changePassword">
      <div className="company">
        <img src={CompanyLogo} />
        <div className="colorHeading">Sustainability </div>{" "}
        <div> Marketplace</div>
      </div>

      <img className="changePassword-image" src={login} alt="image" />
      <div className="main-part">
        <h2 className="heading">Change Password</h2>
        <div className="body">
          <form onSubmit={handleSubmit}>
            <div className="formPart">
              <div className="rest-form-part">
                <label htmlFor="email" className="label">
                  E-mail <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="email"
                  className="input"
                  name="email"
                  onChange={handleChange}
                  value={formData.email}
                />
              </div>

              <div className="rest-form-part">

              <div>
                  <div className="hide-password">
                    <label htmlFor="password" className="label">
                      Old Password <span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="eye">
                      <button
                        type="button"
                        className="eye-button"
                        onClick={() => setShowOldPassword(!showOldPassword)}
                      >
                        {!showOldPassword ? (
                          <div className="eye-icon">
                            <img src={eye} alt="x" />
                            Show
                          </div>
                        ) : (
                          <div className="eye-icon">
                            <img src={eyeSlash} />
                            Hide
                          </div>
                        )}
                      </button>
                    </div>
                  </div>
                </div>

                <input
                  type={showOldPassword ? "text" : "password"}
                  name="password"
                  placeholder=""
                  className="password"
                  onChange={handleChange}
                  value={formData.password}
                />
              </div>

              <div className="rest-form-part">
                
                <div>
                  <div className="hide-password">
                    <label htmlFor="password" className="label">
                      New Password <span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="eye">
                      <button
                        type="button"
                        className="eye-button"
                        onClick={() => setShowNewPassword(!showNewPassword)}
                      >
                        {!showNewPassword ? (
                          <div className="eye-icon">
                            <img src={eye} alt="x" />
                            Show
                          </div>
                        ) : (
                          <div className="eye-icon">
                            <img src={eyeSlash} />
                            Hide
                          </div>
                        )}
                      </button>
                    </div>
                  </div>
                </div>

                <input
                  type={showNewPassword ? "text" : "password"}
                  name="newPassword"
                  placeholder=""
                  className="password"
                  onChange={handleChange}
                  value={formData.newPassword}
                />
              </div>

              <div className="rest-form-part">
                <label htmlFor="password" className="label">
                  Confirm Password <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="password"
                  name="confirmPassword"
                  placeholder=""
                  className="password"
                  onChange={handleChange}
                  value={formData.confirmPassword}
                />
              </div>

              <ul>
                  {validation.length ? (
                    <li style={{ color: "green" }}>Use 8 or more character</li>
                  ) : (
                    <li style={{ color: "red" }}>Use 8 or more character</li>
                  )}
                  {validation.upperCase ? (
                    <li style={{ color: "green" }}>One Uppercase character</li>
                  ) : (
                    <li style={{ color: "red" }}>One Uppercase character</li>
                  )}
                  {validation.specialCase ? (
                    <li style={{ color: "green" }}>One Special character</li>
                  ) : (
                    <li style={{ color: "red" }}>One Special character</li>
                  )}
                  {validation.lowerCase ? (
                    <li style={{ color: "green" }}>One Lowercase character</li>
                  ) : (
                    <li style={{ color: "red" }}>One Lowercase character</li>
                  )}
                  {validation.number ? (
                    <li style={{ color: "green" }}>One number</li>
                  ) : (
                    <li style={{ color: "red" }}>One number</li>
                  )}
                </ul>

              <h4 className="subheading">
                Don't have an account?{" "}
                <span>
                  {" "}
                  <Link className="changepassword-link" to="/signup">
                    Sign up
                  </Link>
                </span>{" "}
              </h4>
              {error ? <div className="error">{error}</div> : ""}
              <div className="captcha-part mt-1">
                <div className="buttonPart">
                  <button type="submit" className="card-button">
                    {isLoading ? "Loading..." : "Change Password"}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
