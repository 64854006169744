import { useState, ChangeEvent } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateUserDetailsApi } from "../../../../redux/slice/userAuthSlice/Login/userLoginSlice";
import { changePasswordapi } from "../../../../redux/slice/userAuthSlice/Changepassword/changePasswordSlice";
import { AppDispatch } from "../../../../redux/store";
import { toast } from "react-toastify";
import useGetUserInfo from "./hooks/useGetUserInfo";

interface FormData {
  firstName?: string;
  lastName?: string;
  companyName?: string;
  password: string;
  newPassword: string;
  confirmPassword: "";
}

interface EditForm {
  names: boolean;
  companyName: boolean;
  password: boolean;
}

type SaveFuncParam = {
  src: string;
};

interface ErrorType {
  firstName: boolean;
  lastName: boolean;
  companyName: boolean;
}

interface ValidationData {
  upperCase: boolean;
  lowerCase: boolean;
  specialCase: boolean;
  length: boolean;
  number: boolean;
}

interface UserInfo {
  firstName?: string;
  lastName?: string;
  company_name?: string;
  email?: string;
}

const useAccountSetting = () => {
  const { email } = useSelector(
    (state: any) => state?.userLogin?.response?.result?.data?.payload
  );

  const dispatch = useDispatch<AppDispatch>();

  const {formData, loading, setFormData, getUserInfo} : {formData: FormData, loading: boolean, setFormData: any, getUserInfo: any} = useGetUserInfo();
  
  const [updateDetailsLoading, setUpdateDetailsLoading] = useState(false);
  

  const [error, setError] = useState<ErrorType>({
    firstName: false,
    lastName: false,
    companyName: false,
  });

  const [editState, setEditState] = useState({
    names: false,
    companyName: false,
    password: false,
  });

  const [validation, setValidation] = useState<ValidationData>({
    upperCase: false,
    lowerCase: false,
    specialCase: false,
    length: false,
    number: false,
  });

  const { names, companyName, password } = editState;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;

    if (name == "newPassword") {
      setValidation({
        length: value.length >= 8,
        upperCase: /[A-Z]/.test(value),
        lowerCase: /[a-z]/.test(value),
        number: /[0-9]/.test(value),
        specialCase: /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(value)
      });
    }

    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));

    setError((prev) => ({ ...prev, [name]: !value }));
  };

  const handleEdit = (src: string) => {
    setEditState((prev) => ({
      ...prev,
      [src]: !prev[src as keyof EditForm],
    }));

    setError({
      firstName: false,
      lastName: false,
      companyName: false,
    });
  };

  const handleSave = ({ src }: SaveFuncParam) => {
    if (src !== "password") {
      const payload = {
        updatedFirstName: formData.firstName,
        updatedLastName: formData.lastName,
        updatedCompanyName: formData.companyName
      };

      if (!formData.firstName || !formData.lastName || !formData.companyName) {
        setError({
          firstName: !formData.firstName,
          lastName: !formData.lastName,
          companyName: !formData.companyName,
        });
        return;
      }

      setUpdateDetailsLoading(true);

      dispatch(updateUserDetailsApi(payload)).then((res: any) => {
        if (res?.payload?.resObj?.ok === true) {
          setEditState((prev) => ({
            ...prev,
            [src]: false,
          }));
          toast.success('Details updated successfully!');

          getUserInfo();
        }
        if (res.payload?.error) {
          toast.error(res.payload?.error);
        }

        setUpdateDetailsLoading(false);
      });
    } else {
      if (Object.values(validation).some(value => !value)) return;

      if (formData.confirmPassword !== formData.newPassword) {
        toast.error("Passwords do not match");
        return;
      }

      const payload = {
        email,
        password: formData.password,
        newPassword: formData.newPassword,
      };

      setUpdateDetailsLoading(true);

      dispatch(changePasswordapi(payload)).then((res) => {
        if (res.payload?.result?.success == true) {
          setEditState((prev) => ({
            ...prev,
            [src]: false,
          }));
          toast.success("Password changed successfully!");

          setFormData((prev: any) => ({
            ...prev,
            password: "",
            newPassword: "",
            confirmPassword: "",
          }));
        }
        if (res.payload?.error) {
          toast.error(res.payload?.error);
        }
        setUpdateDetailsLoading(false);
      });
    }
  };

  return {
    formData,
    names,
    companyName,
    password,
    handleChange,
    handleEdit,
    handleSave,
    error,
    validation,
    loading,
    updateDetailsLoading,
  };
};

export default useAccountSetting;
