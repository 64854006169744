import {
  ChangeEvent,
  useState,
  useRef,
  useEffect,
  MouseEventHandler,
} from "react";
import "./EmployeesList.scss";
import removeUser from "../../../assets/svg/removeUser.svg";
import editIcon from "../../../assets/svg/editIcon.svg";
import AccessControl from "./AccessControl";
import useGetEmployees from "./hooks/useGetEmployees";
import UserEmptyState from "../ManageUser/components/UserEmptyState/UserEmptyState";
import TableLoadingState from "../ManageUser/components/TableLoadingState/TableLoadingState";
import Eye from "../../../assets/svg/Eye";
import SearchIcon from "../../../assets/svg/SearchIcon";
import { useLocation } from "react-router-dom";
import CheckboxSelect from "./CheckboxSelect/CheckboxSelect";

interface Module {
  moduleName: string;
  accessType: keyof AccessType;
}
interface Employee {
  firstName: string;
  lastName: string;
  role: string;
  email: string;
  module_access: Module[];
}

const moduleColor = ["#FFD6D6", "#E3F6D4", "#D6EBFF"];

interface AccessType {
  read: string;
  write: string;
}

const accessTypeMapping: AccessType = {
  read: "Read Only",
  write: "Read/Write",
};

const EmployeesList = ({
  employeeRef,
  handleEditClick,
  setUploadPopup,
  handleRemoveClick,
  setShowPopup,
}: {
  employeeRef: any;
  handleEditClick: any;
  setUploadPopup: any;
  handleRemoveClick: any;
  setShowPopup: any;
}) => {
  const location = useLocation();
  const sector = location.state.sector;

  const cart = location.state.cart || {};
  const cartDetails = cart.items;

  const modules = cartDetails.map((item: any) => ({name: item.moduleName, value: item.moduleName.replace(/\s/g, '').toLowerCase()}));

  const popoverRef = useRef<HTMLDivElement>(null);
  const [isOverlayvisible, setOverlayVisible] = useState(false);

  const {
    employeeList,
    loading,
    formData,
    setFormData,
    handleSearchApply,
    handleResetFilters,
    handleApplyFilters,
    filters,
    setFilters,
    showFilterPopover,
    setShowFilterPopover,
    togglePopover,
    search,
    setSearch,
    subSection,
    setSubSection,
    openModule,
    setOpenModule,
    queryString,
    setQueryString,
  }: {
    employeeList: Employee[];
    loading: boolean;
    formData: any;
    setFormData: any;
    handleSearchApply: MouseEventHandler<HTMLButtonElement>;
    handleApplyFilters: MouseEventHandler<HTMLButtonElement>;
    handleResetFilters: MouseEventHandler<HTMLButtonElement>;
    filters: any;
    setFilters: any,
    showFilterPopover: boolean;
    setShowFilterPopover: any;
    togglePopover: MouseEventHandler<HTMLDivElement>;
    search: string;
    setSearch: any;
    subSection: string,
    setSubSection: any,
    openModule: any,
    setOpenModule: any,
    queryString: {
      search: string,
      filter: string
    },
    setQueryString: any,
  } = useGetEmployees(employeeRef, modules);

  const handleAccessButtonClick = () => {
    setOverlayVisible(!isOverlayvisible);
  };

  const handleInput = (e: ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    setFormData({ ...formData, [name]: value });
  };

  const handleClickOutside = (event: any) => {
    if (popoverRef.current && !popoverRef.current.contains(event.target)) {
      setShowFilterPopover(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="tableHeader" ref={popoverRef}>
        <div className="buttonPart">
          <button
            type="button"
            onClick={handleAccessButtonClick}
            className="loginbutton card-button access-control-header-btn"
          >
            Access Details <Eye color="#fff" />
          </button>

          <div className="access-detail">
            {isOverlayvisible && (
              <AccessControl
                handleAccessButtonClick={handleAccessButtonClick}
              />
            )}
          </div>
        </div>
        <div className="rightHeader">
          <div className="search-container">
            <div className="search-inner-container">
              <div className="search-input">

              <div className="search-icon">
                <SearchIcon />
              </div>

                <input
                  type="text"
                  placeholder={queryString.search ? "" : "Search..."}
                  value={search}
                  disabled={!!queryString.search}
                  onChange={(e) => setSearch(e.target.value)}
                />

                {queryString.search ? (
                  <div className="search-value">
                    <div className="search-value-text">{queryString.search}</div>
                    <div
                      className="cross-mark"
                      role="button"
                      onClick={() => setQueryString((prev: {search: string, filter: string}) => ({
                        ...prev,
                        search: "",
                      }))}
                    />
                  </div>
                ) : null}
              </div>
            </div>

            <div className="buttonPart">
              <button
                disabled={!!queryString.search}
                className="loginbutton card-button "
                onClick={handleSearchApply}
                style={queryString.search ? {pointerEvents: 'none'} : {}}
              >
                Apply
              </button>
            </div>
          </div>

          <div className="filter-container">
            <div
              className="filter-button"
              role="button"
              onClick={togglePopover}
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.1069 13.5018H13.8528M15.4753 11.916V15.1702M19.8294 13.5202C19.8294 14.336 19.6003 15.106 19.1969 15.766C18.8132 16.4105 18.2682 16.9439 17.6156 17.3136C16.963 17.6833 16.2253 17.8766 15.4753 17.8743C14.7256 17.8747 13.9886 17.6806 13.3364 17.3111C12.6841 16.9415 12.1387 16.4092 11.7536 15.766C11.3384 15.0906 11.1194 14.313 11.1211 13.5202C11.1211 11.1185 13.0736 9.16602 15.4753 9.16602C17.8769 9.16602 19.8294 11.1185 19.8294 13.5202Z"
                  stroke="#808080"
                  style={{
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeMiterlimit: "10",
                  }}
                />
                <path
                  d="M18.9668 3.68565V5.72065C18.9668 6.46315 18.4993 7.38898 18.041 7.85648L16.4277 9.27732C16.1154 9.20301 15.7953 9.16609 15.4743 9.16732C13.0727 9.16732 11.1202 11.1198 11.1202 13.5215C11.1202 14.3373 11.3493 15.1073 11.7527 15.7673C12.0918 16.3357 12.5593 16.8215 13.1277 17.1698V17.4815C13.1277 18.0407 12.761 18.7832 12.2935 19.0582L11.001 19.8923C9.80016 20.6348 8.13182 19.8007 8.13182 18.3157V13.4115C8.13182 12.7607 7.75599 11.9265 7.38932 11.4682L3.86932 7.76482C3.41099 7.29732 3.03516 6.46315 3.03516 5.91315V3.77732C3.03516 2.66815 3.86932 1.83398 4.88682 1.83398H17.1152C18.1327 1.83398 18.9668 2.66815 18.9668 3.68565Z"
                  stroke="#808080"
                  style={{
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeMiterlimit: "10",
                  }}
                />
              </svg>
              Filter
            </div>

            {queryString.filter ? <div className="filter-bob" /> : null}

            {showFilterPopover && (
              <div className="popover-content">
                <CheckboxSelect entries={modules} filters={filters} setFilters={setFilters} openModule={openModule} setOpenModule={setOpenModule} />

                <div className="filter-popover-btn-container">
                  <button className="card-button filter-light-btn" onClick={handleResetFilters}>
                    Reset
                  </button>
                  <button className="card-button" onClick={handleApplyFilters}>Apply</button>
                </div>

              </div>
            )}
          </div>

          <div className="buttonPart">
            <button
              type="submit"
              className="loginbutton card-button "
              onClick={() => setShowPopup(true)}
            >
              + Add Employee
            </button>
          </div>
          <div className="buttonPart">
            <button
              type="submit"
              className="loginbutton card-button"
              onClick={() => setUploadPopup("employee")}
            >
              + Upload file
            </button>
          </div>
        </div>
      </div>

      <table className="userDetailsTable">
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Role</th>
            <th>Module</th>
            <th>Access Type</th>
            <th></th>
          </tr>
        </thead>

        {!loading ? (
          <tbody>
            {employeeList.map((item, index) => (
              <tr key={index}>
                <td>{item.firstName}</td>
                <td>{item.lastName}</td>
                <td>{item.email}</td>
                <td>{item.role}</td>
                <td>
                  <ul>
                    {item.module_access &&
                      item.module_access.length > 0 &&
                      item?.module_access.map((subItem, index) => (
                        <li
                          key={index}
                          style={{ backgroundColor: moduleColor[index] }}
                        >
                          {subItem.moduleName}
                        </li>
                      ))}
                  </ul>
                </td>
                <td>
                  <ul>
                    {item.module_access &&
                      item.module_access.length > 0 &&
                      item?.module_access.map((subItem, index) => (
                        <li
                          key={index}
                          style={{ backgroundColor: moduleColor[index] }}
                        >
                          {accessTypeMapping[subItem.accessType]}
                        </li>
                      ))}
                  </ul>
                </td>
                <td>
                  <div className="userEditButton">
                    <button
                      className="editButton"
                      onClick={() => handleEditClick(item)}
                    >
                      <img src={editIcon} alt="x" className="editUser" />
                    </button>
                    <button
                      className="editButton"
                      onClick={() => handleRemoveClick(item.email, "employee")}
                    >
                      <img src={removeUser} alt="x" className="removeUser" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        ) : null}
      </table>

      {!loading && employeeList.length === 0 ? (
        <UserEmptyState
          text="No Employees found"
          subText="Add Employees to display here"
        />
      ) : null}

      {loading ? <TableLoadingState /> : null}
    </>
  );
};

export default EmployeesList;
