import React from 'react';
import './TableLoadingState.scss';

const TableLoadingState = () => {
  return (
    <div className="bouncing-loader">
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
  );
};

export default TableLoadingState;
