import React from 'react'
import { useEffect, useState } from 'react';
import MobileHeader from '../../Headers/MobileHeader/MobileHeader';
import Header from '../../Headers/DesktopHeader/Header';
import { Outlet } from 'react-router-dom';


const Layout = ({ children }: { children: React.ReactNode }) => {
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 680);
        };

        handleResize();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);


    return (
        <div>
            {isMobile ? <MobileHeader /> : <Header />}
            <Outlet />
            {children}
        </div>
    )
}

export default Layout