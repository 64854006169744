import AccountSetting from "../../../assets/svg/userProfileAsset/AccountingSetting.svg";
import ContactSupport from "../../../assets/svg/userProfileAsset/contactSupport.svg";
import OrderHistory from "../../../assets/svg/userProfileAsset/orderHistory.svg";

const profileSections = [{
    title:'Account Setting',
    img: AccountSetting,
    pathUrl:'/profile'
},
{
    title:'Order History',
    img: OrderHistory,
    pathUrl:'/orderhistory'
},
{
    title:'Contact Support',
    img: ContactSupport,
    pathUrl:'/contact-support'
}];


export default profileSections;