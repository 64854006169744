import { useEffect, useState, useImperativeHandle, useCallback } from "react";
import api from "../../../../api";
import { toast } from "react-toastify";

const useGetInvestees = (investeeRef: any) => {
  const [investeeList, setInvesteeList] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [search, setSearch] = useState("");
  const [queryString, setQueryString] = useState("");

  const handleSearchApply = () => {
    setQueryString(search);
    setSearch("");
  };

  const getInvestees = useCallback(async () => {
    const url = queryString
      ? `${api.config.getInvestees()}?search=${queryString}`
      : api.config.getInvestees();

    try {
      setLoading(true);

      const response = await fetch(url, api.http.get());
      const result = await api.afterFetchHandlers.parseContent(
        response,
        api.http.get()
      );
      setInvesteeList(result.result || []);
    } catch (error: any) {
      toast.error(error?.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  }, [setInvesteeList, queryString]);

  useEffect(() => {
    getInvestees();
  }, [getInvestees]);

  useImperativeHandle(
    investeeRef,
    () => ({
      getInvestees,
    }),
    [getInvestees]
  );

  return {
    investeeList,
    getInvestees,
    loading,
    search,
    setSearch,
    queryString,
    setQueryString,
    handleSearchApply,
  };
};

export default useGetInvestees;
