import { combineReducers } from "@reduxjs/toolkit";
import signupReducer from "./slice/userAuthSlice/Signup/signupSlice";
import userVerifyReducer from "./slice/userAuthSlice/Accountverify/userVerifySlice"
import cartReducer from "./slice/cartSlice/cartSlice"
import userLoginReducer from "./slice/userAuthSlice/Login/userLoginSlice"
import cartDetailsReducer from "./slice/cartSlice/cartApiSlice"
import employeeDetailsAPIReducer from "./slice/manageUserSlice/Employee/employeeApiSlice"
import investeeDetailsAPIReducer from "./slice/manageUserSlice/Investee/investeeApiSlice"
import getUserDetailsAPIReducer  from "./slice/manageUserSlice/Employee/employeeGetApiSlice";
import resendCodeReducer from "./slice/userAuthSlice/ResendCode/resendCodeSlice";
import ConfirmOnboardingReducer from "./slice/manageUserSlice/ConfirmOnboardingSlice";
import investeeResendReducer from "./slice/manageUserSlice/Investee/investeeResendSlice";
import changePasswordReducer from "./slice/userAuthSlice/Changepassword/changePasswordSlice"


export const rootReducer = combineReducers({
    signupDetails: signupReducer,
    userVerify: userVerifyReducer,
    cart:cartReducer,
    cartApi: cartDetailsReducer,
    userLogin: userLoginReducer,
    employeeDetailsApi: employeeDetailsAPIReducer,
    investeeDetailsApi: investeeDetailsAPIReducer,
    getUserDetailsApi: getUserDetailsAPIReducer,
    resendCodeDetails: resendCodeReducer,
    resendInvesteeInvitations: investeeResendReducer,
    confirmEmployeeOnboardDetails: ConfirmOnboardingReducer,
    changePassword: changePasswordReducer
})



