import React from 'react'

const Insurer = ({color}:{color:any}) => {
  return (
    <div><svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.2713 3.30264C16.1982 3.24278 16.0932 3.24226 16.0195 3.30139L2.0438 14.5145C1.89645 14.6327 1.98005 14.8705 2.16896 14.8705H4.8393C4.94975 14.8705 5.0393 14.96 5.0393 15.0705V28.6C5.0393 28.7104 5.12884 28.7999 5.2393 28.7999H26.4393C26.5498 28.7999 26.6393 28.7104 26.6393 28.6V15.0705C26.6393 14.96 26.7288 14.8705 26.8393 14.8705H29.8401C30.028 14.8705 30.1122 14.6348 29.9668 14.5158L16.2713 3.30264ZM19.3484 24.4925C19.3771 24.6178 19.282 24.7372 19.1535 24.7372H12.8462C12.7177 24.7372 12.6225 24.6177 12.6513 24.4924L13.9923 18.6546C14.0109 18.5739 13.9774 18.4905 13.9103 18.4419C13.1108 17.8625 12.5951 17.0188 12.5951 15.9959C12.5951 14.2327 14.1197 12.8391 15.9995 12.8391C17.8805 12.8391 19.4045 14.286 19.4045 16.0493C19.4045 17.0726 18.8889 17.86 18.0902 18.4328C18.0229 18.4811 17.9893 18.5647 18.0079 18.6455L19.3484 24.4925Z" fill={color}/>
    </svg>
    </div>
  )
}

export default Insurer