import React, { useState, useRef, useEffect } from "react";
import CompanyLogo from "../../../../assets/svg/company-logo.svg";
import Globe from "../../../../assets/svg/globe.svg";
import ArrowDown from "../../../../assets/svg/arrow-down.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import UserProfile from "../../UserProfile/UserProfile";
import "./Header.scss";
import { useDispatch, useSelector } from "react-redux";
import { logoutDetails } from "../../../../redux/slice/userAuthSlice/Login/userLoginSlice";
import { AppDispatch } from "../../../../redux/store";
import headerProductDetails from "../../../../utils/configurations/headerProductDetails";
import Flag from "../../../../assets/svg/usa-flag.svg";

const Header = ({ userName }: { userName?: string }) => {
  const location = useLocation();
  const popupRef = useRef<HTMLDivElement>(null);
  const userProfileRef = useRef<HTMLDivElement>(null);
  const [openLanguageSelect, setOpenLanguageSelect] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();

  const path = location.pathname;

  const AuthHeader = [
    "/login",
    "/signup",
    "/accountverify",
    "/changepassword",
    "/resetpassword",
  ];

  const [userProfileDropdown, setUserProfileDropdown] = useState(false);
  const navigate = useNavigate();

  const userLogin = useSelector((state: any) => state.userLogin || {});

  const handleProfileLogout = () => {
    dispatch(logoutDetails(null)).then((result: any) => {
      localStorage.clear();
      navigate("/login");
    });
  };

  const emails: string | null = localStorage.getItem("email");
  const randomColor: any = localStorage.getItem("randomcolor");

  const handleProfileClick = () => {
    setUserProfileDropdown(!userProfileDropdown);
  };

  const handleRequestDemo = () => {
    document.cookie =
      "myCookie=cookieValue; path=/; domain=semarketplace.localhost; SameSite=None;Secure";
  };

  const userRole = userLogin.response?.result?.data?.payload?.user_role;

  const outsidepopup = (event: any) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setOpenLanguageSelect(false);
    }

    if (
      userProfileRef.current &&
      !userProfileRef.current.contains(event.target)
    ) {
      setUserProfileDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", outsidepopup);
    return () => {
      document.removeEventListener("mousedown", outsidepopup);
    };
  }, []);

  return (
    <>
      {!AuthHeader.includes(path) ? (
        <div className="header-container">
          <div className="left-container">
            <img src={CompanyLogo} alt="sustainabilty economics" />

            {userRole !== 'employee' ? <Link to="/" className={path === '/' ? "tagline active-link" : "tagline"}>Build Your Platform</Link> : null}

            <div className="product-dropdown">
              <span style={{cursor: 'pointer'}}>
                Products <img className="drop-down" src={ArrowDown} alt="x" />
              </span>
              <div className="product-content">
                <div className="product-list">
                  {headerProductDetails.map((item: any, index) => ( <li key={item.title}>
                    <Link className="link" to={"/readmore"} state={index}>
                      <img src={item.imgSrc} alt={item.title} />
                      {item.title}
                    </Link>
                  </li>))}
                </div>
              </div>
            </div>

            {!(userRole === "user") ? (
              <Link
                to="/sectors"
                className={
                  path === "/sectors" ? "tagline active-link" : "tagline"
                }
              >
                Your Platforms
              </Link>
            ) : null}
          </div>

          <div className="right-container">
            {userRole !== 'user' ? <button className="request-button" onClick={handleRequestDemo}>Request Demo</button> : null}
            
            <div className="globe" onClick={() => setOpenLanguageSelect((prev) => !prev)} ref={popupRef}>
              <img src={Globe} alt="globe" />
              {openLanguageSelect ? (
                <div className="language-select">
                  <img src={Flag} />
                  <div>English</div>
                </div>
              ) : null}
            </div>

            <div
              className="userProfile"
              onClick={handleProfileClick}
              ref={userProfileRef}
            >
              <div
                className="userImage"
                style={{ backgroundColor: randomColor }}
              >
                <div className="userName">
                  {emails && emails[0]?.toUpperCase()}
                </div>
              </div>

              {userProfileDropdown && (
                <UserProfile
                  userRole={userRole}
                  handleProfileLogout={handleProfileLogout}
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default Header;
