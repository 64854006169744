import './ButtonWithSpinner.scss';

type ButtonProps = {
    isLoading?: boolean,
    className?: string,
    children?: any
    [key: string]: any 
}

const ButtonWithSpinner = ({ isLoading, children, className, ...rest } : ButtonProps) => {
  return (
    <button className={`${isLoading ? 'button-loader' : ''} ${className}`} {...rest} disabled={isLoading}>
        <span style={{ visibility: isLoading ? 'hidden' : 'visible' }}>{children}</span>
    </button>
  );
};

export default ButtonWithSpinner;
