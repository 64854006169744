import NewEmployeePopUp from "../NewUserPopUp/NewEmployeePopUp";
import NewInvesteePopUp from "../NewUserPopUp/NewInvesteePopUp";
import Sidebar from "../Sidebar/Sidebar";
import EmployeeList from "../UserDetails/EmployeesList";
import "./ManageUser.scss";
import InvesteeList from "../UserDetails/InvesteeList";
import ContactSupport from "../ContactSupport/ContactSupport";
import UsersUploadPopup from "../NewUserPopUp/UsersUploadPopup";
import useManageUser from "./hooks/useManageUser";
import BackgroundScreen from "../../ProductPlatform/BackgroundScreen/BackgroundScreen";

const ManageUser = () => {
  const {
    popupRef,
    activeTab,
    employeeRef,
    investeeRef,
    setActiveTab,
    activeSubTab,
    setActiveSubTab,
    employeeDetails,
    investeeDetails,
    setEmployeeDetails,
    setInvesteeDetails,
    editEmployee,
    editInvestee,
    addOrUpdateEmployee,
    addOrUpdateInvestee,
    handleRemoveClick,
    showEmployeePopup,
    showInvesteePopup,
    setShowEmployeePopup,
    setShowInvesteePopup,
    uploadPopup,
    setUploadPopup,
    handleEmployeeEditClick,
    handleInvesteeEditClick,
    onCloseEmployeePopup,
    onCloseInvesteePopup,
  } = useManageUser();

  return (
    <div className="top-container">
      {activeSubTab == "subtab1" && showEmployeePopup && (
        <div className="dialogue">
          <BackgroundScreen>
            <NewEmployeePopUp
              popupRef={popupRef}
              details={employeeDetails}
              setDetails={setEmployeeDetails}
              edit={editEmployee}
              addOrUpdateEmployee={addOrUpdateEmployee}
              onCloseEmployeePopup={onCloseEmployeePopup}
            />
          </BackgroundScreen>
        </div>
      )}

      {uploadPopup && (
        <div className="dialogue">
          <BackgroundScreen>
            <UsersUploadPopup
              popupRef={popupRef}
              employeeRef={employeeRef}
              investeeRef={investeeRef}
              uploadPopup={uploadPopup}
              setUploadPopup={setUploadPopup}
              activeSubTab={activeSubTab}
            />
          </BackgroundScreen>
        </div>
      )}

      {activeSubTab == "subtab2" && showInvesteePopup && (
        <div className="dialogue">
          <BackgroundScreen>
            <NewInvesteePopUp
              popupRef={popupRef}
              details={investeeDetails}
              setDetails={setInvesteeDetails}
              edit={editInvestee}
              addOrUpdateInvestee={addOrUpdateInvestee}
              onCloseInvesteePopup={onCloseInvesteePopup}
            />
          </BackgroundScreen>
        </div>
      )}

      <div className="sidebar">
        <Sidebar
          setActiveSubTab={setActiveSubTab}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          activeSubTab={activeSubTab}
        />
      </div>

      <div className="table-container">
        {activeSubTab == "subtab1" && (
          <EmployeeList
            employeeRef={employeeRef}
            handleEditClick={handleEmployeeEditClick}
            setUploadPopup={setUploadPopup}
            handleRemoveClick={handleRemoveClick}
            setShowPopup={setShowEmployeePopup}
          />
        )}

        {activeSubTab == "subtab2" && (
          <InvesteeList
            investeeRef={investeeRef}
            handleEditClick={handleInvesteeEditClick}
            setUploadPopup={setUploadPopup}
            handleRemoveClick={handleRemoveClick}
            setShowPopup={setShowInvesteePopup}
          />
        )}

        {activeTab == 3 && <ContactSupport />}
      </div>
    </div>
  );
};

export default ManageUser;
