import Finance from "../../../assets/animations/financeIcon.gif";
import FinanceBackground from "../../../assets/animations/finance.gif";
import RealEstate from "../../../assets/animations/realStateIcon.gif";
import Energy from "../../../assets/animations/energyIcon.gif";
import Inf_technology from "../../../assets/animations/informationTechnologyIcon.gif";
import Industry from "../../../assets/animations/industriesIcon.gif";
import Comp from "../../../assets/animations/consumerIcon.gif";
import Health from "../../../assets/animations/healthIcon.gif";
import Utility from "../../../assets/animations/utilitiesIcon.gif";
import Materials from "../../../assets/animations/materialsIcon.gif";
import RealEstateBackground from "../../../assets/animations/realestate.gif";
import EnergyBackground from "../../../assets/animations/energy.gif";
import InformationTechnologyBackground from "../../../assets/animations/informationTechnology.gif";
import IndustriesBackground from "../../../assets/animations/industries.gif";
import ConsumerBackground from "../../../assets/animations/consumer.gif";
import HealthCareBackground from "../../../assets/animations/health.gif";
import UtilitiesBackground from "../../../assets/animations/utilities.gif";
import MaterialsBackground from "../../../assets/animations/materials.gif";
import Bank from "../../../assets/svg/SubModuleIcons/Finance/banks.svg";
import Insurer from "../../../assets/svg/SubModuleIcons/Finance/insures.svg";
import AssetManager from "../../../assets/svg/SubModuleIcons/Finance/assetManager.svg";
import Equipment from "../../../assets/svg/SubModuleIcons/Healthcare/equipment.svg";
import Managed from "../../../assets/svg/SubModuleIcons/Healthcare/managed.svg";
import Pharmacuticals from "../../../assets/svg/SubModuleIcons/Healthcare/Pharmacuticals.svg";
import Coal from "../../../assets/svg/SubModuleIcons/Energy/coal.svg";
import Gas from "../../../assets/svg/SubModuleIcons/Energy/gas.svg";
import HydroPower from "../../../assets/svg/SubModuleIcons/Energy/hydropower.svg";
import Oil from "../../../assets/svg/SubModuleIcons/Energy/oil.svg";
import Solar from "../../../assets/svg/SubModuleIcons/Energy/solar.svg";
import Hotel from "../../../assets/svg/SubModuleIcons/ConsumerDiscretionary/hotel.svg";
import Restaurant from "../../../assets/svg/SubModuleIcons/ConsumerDiscretionary/restaurant.svg";
import Transportation from "../../../assets/svg/SubModuleIcons/ConsumerDiscretionary/transportation.svg";
import Commercial from "../../../assets/svg/SubModuleIcons/RealEstate/commercial.svg";
import Residential from "../../../assets/svg/SubModuleIcons/RealEstate/residential.svg";
import Electricity from "../../../assets/svg/SubModuleIcons/Utilities/electricity.svg";
import UtilityGas from "../../../assets/svg/SubModuleIcons/Utilities/gas.svg";
import Waste from "../../../assets/svg/SubModuleIcons/Utilities/waste.svg";
import Water from "../../../assets/svg/SubModuleIcons/Utilities/water.svg";
import Hardware from "../../../assets/svg/SubModuleIcons/IT/hardware.svg";
import Service from "../../../assets/svg/SubModuleIcons/IT/service.svg";
import Software from "../../../assets/svg/SubModuleIcons/IT/software.svg";
import AutoMobile from "../../../assets/svg/SubModuleIcons/Industries/automobile.svg";
import Manufacturing from "../../../assets/svg/SubModuleIcons/Industries/manufacturing.svg";
import Metal from "../../../assets/svg/SubModuleIcons/Industries/metal.svg";
import Chemical from "../../../assets/svg/SubModuleIcons/Materials/chemical.svg";
import CommercialChemical from "../../../assets/svg/SubModuleIcons/Materials/commercial_chemical.svg";
import Construction from "../../../assets/svg/SubModuleIcons/Materials/construction.svg";
import MaterialGas from "../../../assets/svg/SubModuleIcons/Materials/gas.svg";


export const FinanceCardsItem = [
  [
    {
      id: 1,
      title: "Bank",
      content: [
        "End to End transition Climate and Nature solution for your entire portfolio from your corporate investments to your mortgage portfolio.  ",
      ],
      logo: Bank,
    },
    {
      id: 2,
      title: "Insurer",
      content: [
        "Transition your insurance portfolio to Net Zero and Nature Positive with ease. Build a sustainable and risk resilient insurance portfolio across all your line of business.  ",
      ],
      logo: Insurer,
    },
    {
      id: 3,
      title: "Asset Manager",
      content: [
        "Transition your insurance portfolio to Net Zero and Nature Positive with ease. Build a sustainable and risk resilient insurance portfolio across all your line of business.  ",
      ],
      logo: AssetManager,
    },
  ],
  [
    {
      id: 1,
      title: "Commercial",
      content: [
        "Lorem ipsum dolor sit",
        "Lorem ipsum dolor ",
        "Lorem ip dolor sit ",
        "Lorem ipsut",
        "Lorem ipsdolor sit",
      ],
      logo: Commercial,
    },
    {
      id: 2,
      title: "Residential",
      content: [
        "Lorem ipsum dolor sit",
        "Lorem ipsum dolor ",
        "Lorem ip dolor sit ",
        "Lorem ipsut",
        "Lorem ipsdolor sit",
      ],
      logo: Residential,
    },
  ],

  [
    {
      id: 1,
      title: "Oil",
      content: [
        "Lorem ipsum dolor sit",
        "Lorem ipsum dolor ",
        "Lorem ip dolor sit ",
        "Lorem ipsut",
        "Lorem ipsdolor sit",
      ],
      logo: Oil,
    },
    {
      id: 2,
      title: "Gas",
      content: [
        "Lorem ipsum dolor sit",
        "Lorem ipsum dolor ",
        "Lorem ip dolor sit ",
        "Lorem ipsut",
        "Lorem ipsdolor sit",
      ],
      logo: Gas,
    },
    {
      id: 3,
      title: "Solar",
      content: [
        "Lorem ipsum dolor sit",
        "Lorem ipsum dolor ",
        "Lorem ip dolor sit ",
        "Lorem ipsut",
        "Lorem ipsdolor sit",
      ],
      logo: Solar,
    },
    {
      id: 4,
      title: "Hydropower",
      content: [
        "Lorem ipsum dolor sit",
        "Lorem ipsum dolor ",
        "Lorem ip dolor sit ",
        "Lorem ipsut",
        "Lorem ipsdolor sit",
      ],
      logo: HydroPower,
    },
    {
      id: 5,
      title: "Coal Alternatives",
      content: [
        "Lorem ipsum dolor sit",
        "Lorem ipsum dolor ",
        "Lorem ip dolor sit ",
        "Lorem ipsut",
        "Lorem ipsdolor sit",
      ],
      logo: Coal,
    },
  ],
  [
    {
      id: 1,
      title: "Hardware",
      content: [
        "Lorem ipsum dolor sit",
        "Lorem ipsum dolor ",
        "Lorem ip dolor sit ",
        "Lorem ipsut",
        "Lorem ipsdolor sit",
      ],
      logo: Hardware,
    },
    {
      id: 2,
      title: "Services",
      content: [
        "Lorem ipsum dolor sit",
        "Lorem ipsum dolor ",
        "Lorem ip dolor sit ",
        "Lorem ipsut",
        "Lorem ipsdolor sit",
      ],
      logo: Service,
    },
    {
      id: 3,
      title: "Software",
      content: [
        "Lorem ipsum dolor sit",
        "Lorem ipsum dolor ",
        "Lorem ip dolor sit ",
        "Lorem ipsut",
        "Lorem ipsdolor sit",
      ],
      logo: Software,
    },
  ],
  [
    {
      id: 1,
      title: "Manufacturing",
      content: [
        "Lorem ipsum dolor sit",
        "Lorem ipsum dolor ",
        "Lorem ip dolor sit ",
        "Lorem ipsut",
        "Lorem ipsdolor sit",
      ],
      logo: Manufacturing,
    },
    {
      id: 2,
      title: "Automobile",
      content: [
        "Lorem ipsum dolor sit",
        "Lorem ipsum dolor ",
        "Lorem ip dolor sit ",
        "Lorem ipsut",
        "Lorem ipsdolor sit",
      ],
      logo: AutoMobile,
    },
    {
      id: 3,
      title: "Metals and more",
      content: [
        "Lorem ipsum dolor sit",
        "Lorem ipsum dolor ",
        "Lorem ip dolor sit ",
        "Lorem ipsut",
        "Lorem ipsdolor sit",
      ],
      logo: Metal,
    },
  ],
  [
    {
      id: 1,
      title: "Hotels",
      content: [
        "Lorem ipsum dolor sit",
        "Lorem ipsum dolor ",
        "Lorem ip dolor sit ",
        "Lorem ipsut",
        "Lorem ipsdolor sit",
      ],
      logo: Hotel,
    },
    {
      id: 2,
      title: "Restaurant Chains",
      content: [
        "Lorem ipsum dolor sit",
        "Lorem ipsum dolor ",
        "Lorem ip dolor sit ",
        "Lorem ipsut",
        "Lorem ipsdolor sit",
      ],
      logo: Restaurant,
    },
    {
      id: 3,
      title: "Transportation",
      content: [
        "Lorem ipsum dolor sit",
        "Lorem ipsum dolor ",
        "Lorem ip dolor sit ",
        "Lorem ipsut",
        "Lorem ipsdolor sit",
      ],
      logo: Transportation,
    },
  ],
  [
    {
      id: 1,
      title: "Equipment",
      content: [
        "Lorem ipsum dolor sit",
        "Lorem ipsum dolor ",
        "Lorem ip dolor sit ",
        "Lorem ipsut",
        "Lorem ipsdolor sit",
      ],
      logo: Equipment,
    },
    {
      id: 2,
      title: "Pharmaceuticals",
      content: [
        "Lorem ipsum dolor sit",
        "Lorem ipsum dolor ",
        "Lorem ip dolor sit ",
        "Lorem ipsut",
        "Lorem ipsdolor sit",
      ],
      logo: Pharmacuticals,
    },
    {
      id: 3,
      title: "Managed Healthcare and more",
      content: [
        "Lorem ipsum dolor sit",
        "Lorem ipsum dolor ",
        "Lorem ip dolor sit ",
        "Lorem ipsut",
        "Lorem ipsdolor sit",
      ],
      logo: Managed,
    },
  ],
  [
    {
      id: 1,
      title: "Electricity",
      content: [
        "Lorem ipsum dolor sit",
        "Lorem ipsum dolor ",
        "Lorem ip dolor sit ",
        "Lorem ipsut",
        "Lorem ipsdolor sit",
      ],
      logo: Electricity,
    },
    {
      id: 2,
      title: "Gas",
      content: [
        "Lorem ipsum dolor sit",
        "Lorem ipsum dolor ",
        "Lorem ip dolor sit ",
        "Lorem ipsut",
        "Lorem ipsdolor sit",
      ],
      logo: UtilityGas,
    },
    {
      id: 3,
      title: "Water",
      content: [
        "Lorem ipsum dolor sit",
        "Lorem ipsum dolor ",
        "Lorem ip dolor sit ",
        "Lorem ipsut",
        "Lorem ipsdolor sit",
      ],
      logo: Water,
    },
    {
      id: 4,
      title: "Waste",
      content: [
        "Lorem ipsum dolor sit",
        "Lorem ipsum dolor ",
        "Lorem ip dolor sit ",
        "Lorem ipsut",
        "Lorem ipsdolor sit",
      ],
      logo: Waste,
    },
  ],
  [
    {
      id: 1,
      title: "Commodity Chemicals",
      content: [
        "Lorem ipsum dolor sit",
        "Lorem ipsum dolor ",
        "Lorem ip dolor sit ",
        "Lorem ipsut",
        "Lorem ipsdolor sit",
      ],
      logo: Chemical,
    },
    {
      id: 2,
      title: "Gasses",
      content: [
        "Lorem ipsum dolor sit",
        "Lorem ipsum dolor ",
        "Lorem ip dolor sit ",
        "Lorem ipsut",
        "Lorem ipsdolor sit",
      ],
      logo: MaterialGas,
    },
    {
      id: 3,
      title: "Commercial Chemicals",
      content: [
        "Lorem ipsum dolor sit",
        "Lorem ipsum dolor ",
        "Lorem ip dolor sit ",
        "Lorem ipsut",
        "Lorem ipsdolor sit",
      ],
      logo: CommercialChemical,
    },
    {
      id: 4,
      title: "Construction",
      content: [
        "Lorem ipsum dolor sit",
        "Lorem ipsum dolor ",
        "Lorem ip dolor sit ",
        "Lorem ipsut",
        "Lorem ipsdolor sit",
      ],
      logo: Construction,
    },
  ],
];

export const ModuleItems = [
  {
    id: 1,
    title: "Financial Sector",
    content: "Banks • Insurers • Asset managers",
    url: Finance,
    background: FinanceBackground,
    color: "#7B5ECA",
  },
  {
    id: 2,
    title: "Real Estates",
    content: "Commercial • Residential",
    url: RealEstate,
    background: RealEstateBackground,
    color: "#A96A2B",
  },
  {
    id: 3,
    title: "Energy",
    content: "Oil • Gas • Solar • Hydropower • Coal Alternatives",
    url: Energy,
    background: EnergyBackground,
    color: "#EE7434",
  },
  {
    id: 4,
    title: "Information Technology",
    content: "Hardware • Services • Software",
    url: Inf_technology,
    background: InformationTechnologyBackground,
    color: "#83B9D5",
  },
  {
    id: 5,
    title: "Industries",
    content: "Manufacturing  • Automobile • Metals and more",
    url: Industry,
    background: IndustriesBackground,
    color: "#2D5AB0",
  },
  {
    id: 6,
    title: "Consumer Discretionary",
    content: "Hotels • Restaurant Chains • Transportation",
    url: Comp,
    background: ConsumerBackground,
    color: "#01CC9D",
  },
  {
    id: 7,
    title: "Healthcare",
    content: "Equipment • Pharmaceuticals • Managed Healthcare and more",
    url: Health,
    background: HealthCareBackground,
    color: "#D80027",
  },
  {
    id: 8,
    title: "Utilities",
    content: "Electricity • Gas • Water • Waste ",
    url: Utility,
    background: UtilitiesBackground,
    color: "#F2BC41",
  },
  {
    id: 9,
    title: "Materials",
    content:
      "Commodity Chemicals • Gasses • Commercial Chemicals • Construction ",
    url: Materials,
    background: MaterialsBackground,
    color: "#759575",
  },
];
