import { MouseEventHandler, useState } from "react";
import "./InvesteeList.scss";
import removeUser from "../../../assets/svg/removeUser.svg";
import editIcon from "../../../assets/svg/editIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { investeeResendInvite } from "../../../redux/slice/manageUserSlice/Investee/investeeResendSlice";
import useGetInvestees from "./hooks/useGetInvestees";
import UserEmptyState from "../ManageUser/components/UserEmptyState/UserEmptyState";
import TableLoadingState from "../ManageUser/components/TableLoadingState/TableLoadingState";
import SearchIcon from "../../../assets/svg/SearchIcon";
import { toast } from "react-toastify";
import ButtonWithSpinner from "../../components/Utils/ButtonWithSpinner/ButtonWithSpinner";

interface Investee {
  firstName: string;
  lastName: string;
  email: string;
  company_name: string;
  isin?: string;
  onboarding_status: string;
}

const AddInvestee = ({
  investeeRef,
  handleEditClick,
  setUploadPopup,
  handleRemoveClick,
  setShowPopup,
}: {
  investeeRef: any;
  handleEditClick: any;
  setUploadPopup: any;
  handleRemoveClick: any;
  setShowPopup: any;
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const resendInvitationState =
    useSelector((state: any) => state.resendInvesteeInvitations) || {};

  const [isOverlayvisible, setOverlayVisible] = useState(false);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [resendLoader, setResendLoader] = useState<boolean>(false);

  const {
    investeeList,
    loading,
    search,
    setSearch,
    queryString,
    setQueryString,
    handleSearchApply
  }: {
    investeeList: Investee[];
    loading: boolean;
    search: string;
    setSearch: any;
    queryString: string;
    setQueryString: any;
    handleSearchApply: MouseEventHandler<HTMLButtonElement>
  } = useGetInvestees(investeeRef);

  const handleResendInvite = () => {
    const emailObjArray: any = selectedRows.map((item) => ({ email: item }));

    dispatch(investeeResendInvite({ user: emailObjArray })).then((res) => {
      if (res.payload?.success) {
        toast.success("Invitations sent successfully");
        setSelectedRows([]);
      }
    });
  };

  const handleCheckboxChange = (email: string) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows?.includes(email)) {
        return prevSelectedRows?.filter(
          (currentEmail: string) => currentEmail !== email
        );
      } else {
        return [...prevSelectedRows, email];
      }
    });
  };

  const handleResendClick = (email: any) => {
    let user: any = [{ email: email }];
    setResendLoader(true);

    dispatch(investeeResendInvite({ user: user })).then((res) => {
      if (res.payload?.success) {
        toast.success("Invitation sent successfully");
      }
      setResendLoader(false);
    });
  };

  const handleAccessButtonClick = () => {
    setOverlayVisible(!isOverlayvisible);
  };

  return (
    <>
      <div className="tableHeader">
        <div className="leftHeader">
          {/* <div className="buttonPart">
            <button
              type="submit"
              onClick={handleAccessButtonClick}
              className="loginbutton card-button access-control-header-btn"
            >
              Access Details <Eye color="#fff" />
            </button>
            <div className="access-detail">
              {isOverlayvisible && (
                <AccessControl
                  handleAccessButtonClick={handleAccessButtonClick}
                />
              )}
            </div>
          </div> */}

          {investeeList.length !== 0 ? (
            <div className="buttonPart">
              {selectedRows.length === 0 ? (
                <div className="header-text">
                  Select Investees to Resend Invites
                </div>
              ) : (
                <ButtonWithSpinner
                  type="submit"
                  className="loginbutton card-button "
                  onClick={handleResendInvite}
                  isLoading={resendInvitationState.isLoading}
                >
                  Resend Invites
                </ButtonWithSpinner>
              )}
            </div>
          ) : null}
        </div>

        <div className="rightHeader">
          <div className="search-container">
            <div className="search-inner-container">
              <div className="search-input">
                <div className="search-icon">
                  <SearchIcon />
                </div>

                <input
                  type="text"
                  placeholder={queryString ? "" : "Search..."}
                  value={search}
                  disabled={!!queryString}
                  onChange={(e) => setSearch(e.target.value)}
                />

                {queryString ? (
                  <div className="search-value">
                    <div className="search-value-text">{queryString}</div>
                    <div
                      className="cross-mark"
                      role="button"
                      onClick={() => setQueryString("")}
                    />
                  </div>
                ) : null}
              </div>
            </div>

            <div className="buttonPart">
              <button
                disabled={!!queryString}
                className="loginbutton card-button "
                onClick={handleSearchApply}
                style={queryString ? { pointerEvents: "none" } : {}}
              >
                Apply
              </button>
            </div>
          </div>

          <div className="buttonPart">
            <button
              type="submit"
              className="loginbutton card-button "
              onClick={() => setShowPopup(true)}
            >
              + Add Investee
            </button>
          </div>

          <div className="buttonPart">
            <button
              type="submit"
              className="loginbutton card-button"
              onClick={() => setUploadPopup("investee")}
            >
              + Upload file
            </button>
          </div>
        </div>
      </div>
      <table className="userDetailsTable">
        <thead>
          <tr>
            <th></th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Company</th>
            <th>ISIN</th>
            <th>Onboarding</th>
            <th></th>
          </tr>
        </thead>

        {!loading ? (
          <tbody>
            {investeeList.map((item) => (
              <tr key={item.email}>
                <td>
                  <input
                    style={{ cursor: "pointer" }}
                    type="checkbox"
                    checked={selectedRows?.includes(item.email)}
                    onChange={() => handleCheckboxChange(item.email)}
                  />
                </td>
                <td>{item.firstName}</td>
                <td>{item.lastName}</td>
                <td>{item.email}</td>
                <td>{item.company_name}</td>
                <td>{item.isin || '-'}</td>
                <td>
                  {item.onboarding_status ? (
                    <div>Success</div>
                  ) : (
                    <div
                      className={`resendLink ${
                        resendLoader ? "button-loader" : ""
                      }`}
                      onClick={() => handleResendClick(item.email)}
                      style={{
                        cursor: resendLoader ? "not-allowed" : "pointer",
                      }}
                    >
                      {resendLoader ? (
                        <div className="spinner" />
                      ) : (
                        "Invite Again"
                      )}
                    </div>
                  )}
                </td>
                <td>
                  <div className="userEditButton">
                    <button
                      className="editButton"
                      onClick={() => handleEditClick(item)}
                    >
                      <img src={editIcon} alt="x" className="editUser" />
                    </button>
                    <button
                      className="editButton"
                      onClick={() => handleRemoveClick(item.email, "investee")}
                    >
                      <img src={removeUser} alt="x" className="removeUser" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        ) : null}
      </table>

      {!loading && investeeList.length === 0 ? (
        <UserEmptyState
          text="No Investees found"
          subText="Add Investees to display here"
        />
      ) : null}

      {loading ? <TableLoadingState /> : null}
    </>
  );
};

export default AddInvestee;
