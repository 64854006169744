import "./UsersUploadPopup.scss";
import Close from "../../../assets/svg/Close";
import download from "../../../assets/svg/download.svg";
import upload from "../../../assets/svg/upload.svg";
import ButtonWithSpinner from "../../components/Utils/ButtonWithSpinner/ButtonWithSpinner";
import useUploadUserList from "./hooks/useUploadUserList";

type FileUploader = {
  employee: string;
  investee: string;
};

const fileUploaderText: FileUploader = {
  employee: "Employee Details",
  investee: "Investee Details",
};

const UsersUploadPopup = ({
  popupRef,
  employeeRef,
  investeeRef,
  uploadPopup,
  setUploadPopup,
  activeSubTab,
}: {
  popupRef: React.RefObject<HTMLDivElement>;
  employeeRef: any;
  investeeRef: any;
  uploadPopup: string;
  setUploadPopup: any;
  activeSubTab: string | null;
}) => {
  const {
    fileInputRef,
    fileUploadLoading,
    submitForm,
    handleFileChange,
    selectedFile,
    handleDrop,
    handleDragOver,
    handleFileUploadClick,
    sampleFile,
    sampleFileLoading,
  } = useUploadUserList(
    employeeRef,
    investeeRef,
    uploadPopup,
    setUploadPopup,
    activeSubTab
  );

  return (
    <div className="addNewUserPopup" ref={popupRef}>
      <div className="popUpHeader">
        <div className="heading">
          {fileUploaderText[uploadPopup as keyof FileUploader]}
        </div>
        <button
          className="popup-closeButton"
          onClick={() => setUploadPopup("")}
        >
          <Close color={"#000"} width={16} height={16} />
        </button>
      </div>
      <div className="productplanContainer">
        <form
          className="newEmployeeUploadUserForm"
          onSubmit={(e) => submitForm(e, selectedFile)}
        >
          <div className="row">
            <div className="form-group">
              <div className="label">Sample file (For user reference)</div>
              <div className="download">
                <div className="download-name">SampleFile.csv</div>
                <div
                  className={`download-icon ${
                    sampleFileLoading ? "file-loader" : ""
                  }`}
                >
                  {sampleFileLoading ? (
                    <div className="spinner" />
                  ) : (
                    <a
                      href={sampleFile}
                      download="Sample-Format"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      <img src={download} alt="x" />{" "}
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group">
              <div
                className="uploadContainer"
                onDragOver={handleDragOver}
                onDrop={handleDrop}
              >
                <div className="upload-icon">
                  <img src={upload} alt="x" />
                </div>
                <input
                  className="fileInput"
                  ref={fileInputRef}
                  type="file"
                  onChange={handleFileChange}
                  accept=".csv"
                />
                {!selectedFile ? (
                  <div className="upload-text">
                    <span> Drag and Drop here </span>
                    <span> or </span>
                    <span
                      className="browseFile"
                      onClick={handleFileUploadClick}
                    >
                      {" "}
                      Browse File
                    </span>
                  </div>
                ) : (
                  <div className="upload-text">
                    <div>Selected File</div>
                    <div>{selectedFile?.name}</div>
                  </div>
                )}
              </div>
              <div className="file-format">File Format: CSV</div>
            </div>
          </div>

          <div className="buttonPart addUserButton">
            <ButtonWithSpinner
              type="submit"
              className="loginbutton card-button"
              isLoading={fileUploadLoading}
            >
              Submit
            </ButtonWithSpinner>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UsersUploadPopup;
