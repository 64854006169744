import "./FinanceCards.scss";
import { Link } from "react-router-dom";
import Check from "../../../../assets/svg/Check";
import kebabCase from "../../../../utils/kebabCase/kebabCase";

interface FinanceCards {
  logo?: string;
  title?: string;
  content: Array<string>;
  price?: string;
  color?: string;
}

const FinanceCards: React.FC<FinanceCards> = ({
  logo,
  title,
  content,
  price,
  color,
}) => {
  return (
    <div className="Finance">
      <div className="moduleFinance">
        <div className="logoPart">
          <img src={logo} alt="logo" className="logo" />
        </div>
        <div className="cardtitle">{title}</div>

        <div className="contentpart">
          {content.map((item, index) => {
            return (
              <div key={index} className="contentrow">
                <Check color={color} />
                <div className="content">{item}</div>
              </div>
            );
          })}
        </div>
      </div>

      <Link
        to={`/persona/${kebabCase(title)}`}
        state={{ title: title }}
        className="selectButton"
        style={{ backgroundColor: color }}
      >
        Select
      </Link>
    </div>
  );
};

export default FinanceCards;
